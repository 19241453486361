import React, { useEffect, useState } from "react";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  addNewTeam,
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import Select from "react-select";
import { getAgentListAction } from "../Redux/Action/agentAction";
import ProgressBar from "../CommonModals/ProgressBar";
import Loader from "../components/Loader";
import Modal from "react-modal"; // Import react-modal

Modal.setAppElement("#root");

export default function AgentRelationship() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");

  // const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  const agentListingData = useSelector((e) => e.agentAuthData.agentListDetail);
  const playerDataLoadingState = useSelector((e) => e.playerAuthData.loading);

  // const associateAgentOptions = agentListingData?.map((elem) => ({
  //   label: elem?.agent_name,
  //   value: elem?._id,
  // }));

  const [agentOptions, setAgentOptions] = useState(
    agentListingData?.map((elem) => ({
      label: elem?.agent_name,
      value: elem?._id,
    })) || []
  );

  useEffect(() => {
    const agentOptions = agentListingData?.map((elem) => ({
      label: elem?.agent_name,
      value: elem?._id,
    }));

    setAgentOptions(agentOptions);
  }, [agentListingData]);

  const [playerData, setPlayerData] = useState({
    associate_agent_id: "",
    professional_relation1: "",
    who_brought_player1: "",
    associate_agent_id: "",
    agent_other_notes: "",
  });

  const initialValues = {
    associate_agent_id: "",
    professional_relation1: "",
    who_brought_player1: "",
    associate_agent_id: "",
    agent_other_notes: "",
  };

  const validate = (values) => {
    const errors = {};

    if (values.professional_relation1 == 1 && (!values.associate_agent_id ?? !values.associate_agent_id.value)) {
      errors.associate_agent_id = "required";
    }

    return errors;
  };


  const handleSubmit = (values) => {
    console.log("values", values);

    let formData = new FormData();

    if (
      values.professional_relation1 !== null &&
      values.professional_relation1 !== undefined
    ) {
      formData.append("professional_relation", values.professional_relation1);
    }

    if (values.professional_relation1 == 1 && values.associate_agent_id) {
      const associateAgentId = values.associate_agent_id?.value || values.associate_agent_id;
      if (associateAgentId !== null && associateAgentId !== undefined) {
        formData.append("associate_agent_id", associateAgentId);
      }
    }

    if (
      values.agent_other_notes !== null &&
      values.agent_other_notes !== undefined
    ) {
      formData.append("agent_other_notes", values.agent_other_notes);
    }

    if (paylerId !== null && paylerId !== undefined) {
      formData.append("playerId", paylerId);
    }

    formData.append("is_profile_completed", 2);

    dispatch(editPlayerAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        navigate(`/contract/${Encryptedid(res?.payload?.data?._id)}`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    if (paylerId) {
      CallApi();
      dispatch(getAgentListAction());

      const handlePopState = () => {
        navigate(`/profile-info?playerId=${Encryptedid(paylerId)}`, {
          replace: true,
        });
      };

      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [dispatch, paylerId, navigate]);

  const CallApi = async () => {
    const data = await dispatch(getPlayerDataById({ id: paylerId }));

    if (data?.payload.status === 200) {
      const res = data?.payload?.data;

      setPlayerData({
        professional_relation1: res.professional_relation,
        who_brought_player1: res.who_brought_player,
        associate_agent_id: res.associate_agent_id,
        agent_other_notes: res.agent_other_notes,
      });
    }
  };

  const handleBack = () => {
    navigate(`/profile-info?playerId=${Encryptedid(paylerId)}`);
  };

  const handleSelectChange = (option, setFieldValue) => {
    console.log(option, "sdfsdf");
    if (option?.isAgentTeam) {
        setIsModalOpen(true);
    } else {
        setFieldValue("associate_agent_id", option);
    }
  };

  const handleAddTeam = (values, setFieldValue) => {
    if (!newTeamName.trim()) {
      toast.error("Agent name cannot be empty.");
      return;
    }

    dispatch(addNewTeam({ name: newTeamName, type: 0 })).then((res) => {
      if (res?.payload?.status === 200) {
        const newTeamId = res?.payload?.data?._id; 
        const newTeam = { label: newTeamName, value: newTeamId }; 

        setAgentOptions([...agentOptions, newTeam]);
        setFieldValue("associate_agent_id", newTeam.value);

        // setIsFree(true);
        setIsModalOpen(false);
        setNewTeamName("");
      } else {
        toast.error("Failed to add the team. Please try again.");
      }
    });
  };

  // const handleInputChange = (input) => {
  //   const isTeamExists = agentOptions?.some((team) =>
  //     team.label.toLowerCase().includes(input.toLowerCase())
  //   );
  //   setIsAddingTeam(!isTeamExists);
  // };

  const handleInputChange = (input) => {
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, '');
    const isTeamExists = agentOptions?.some((team) =>
      team.label.toLowerCase()?.includes(filteredInput.toLowerCase())
    );
    setIsAddingTeam(!isTeamExists);
    return filteredInput; 
  };
  

  // const customOptions = isAddingTeam
  //   ? [{ label: "Add Agent", value: "add-agent", isAgentTeam: true }]
  //   : agentOptions;

  const customOptions = [
    { label: "+ Add new Agent", value: "add-agent", isAgentTeam: true },
    ...agentOptions,
  ];
  

  const handleSaveAndClose = (values, validateForm) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all the required fields.");
        return;
      }

      let formData = new FormData();
      if (
        values.professional_relation1 !== null &&
        values.professional_relation1 !== undefined
      ) {
        formData.append("professional_relation", values.professional_relation1);
      }

      if (values.professional_relation1 == 1 && values.associate_agent_id) {
        const associateAgentId = values.associate_agent_id?.value || values.associate_agent_id;
        if (associateAgentId !== null && associateAgentId !== undefined) {
          formData.append("associate_agent_id", associateAgentId);
        }
      }

      if (
        values.agent_other_notes !== null &&
        values.agent_other_notes !== undefined
      ) {
        formData.append("agent_other_notes", values.agent_other_notes);
      }

      if (paylerId !== null && paylerId !== undefined) {
        formData.append("playerId", paylerId);
      }

      formData.append("is_profile_completed", 2);
      dispatch(editPlayerAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          navigate("/my-players");
        } else {
          toast.error(res?.payload?.message);
        }
      });
    });
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={2} totalSteps={8} />
          <div className="steps-heading">
            <p>
              <span>Step 2:</span> Agent's Relationship with Player
            </p>
          </div>
          <div className="">
            <div className=" mt-4">
              <Formik
                enableReinitialize={true}
                initialValues={playerData || initialValues}
                validate={validate}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, errors, validateForm }) => (
                  <FormikForm>
                    {playerDataLoadingState && <Loader />}
                    <div className="bottom-lines pb-4">
                      <div className="cmn-form-fields mt-4 brought-current  ">
                        <h3 className="h-20">Professional Relationship</h3>
                        <div className="fist-left-flex new-radio-btn">
                          <div className="licensed-sec player-field">
                            <h3>My Player</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                // type="radio"
                                type="checkbox"
                                role="switch"
                                name="professional_relation1"
                                value="0"
                                as="input"
                                checked={values.professional_relation1 == "0"}
                                onChange={() => {
                                  setFieldValue("professional_relation1", "0");
                                  setFieldValue("associate_agent_id", []);
                                }}
                              />
                            </div>
                          </div>
                          <div className="licensed-sec player-field">
                            <h3>Associate Agent’s Player</h3>
                            <div className="form-check form-switch">
                              <Field
                                className="form-check-input"
                                // type="radio"
                                type="checkbox"
                                role="switch"
                                name="professional_relation1"
                                value={"1"}
                                as="input"
                                checked={values.professional_relation1 == "1"}
                              />
                            </div>
                          </div>
                        </div>

                        <ErrorMessage
                          name="professional_relation1"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {values.professional_relation1 == 1 && (
                        <div className=" player-field mt-4">
                          <div className="d-flex justify-content-between">
                            <h3 className="h-20">Name of Associate Agent</h3>
                          </div>
                          <div className="cmn-form-fields mt-2">
                            {/* <Form.Group
                            className="mb-3"
                            controlId="associate_agent_name"
                          >

                            <Select
                              name="associate_agent_id"
                              placeholder="Search Agent Name"
                              options={associateAgentOptions}
                              isDisabled={values.professional_relation1 != 1}
                              onChange={(option) =>
                                setFieldValue("associate_agent_id", option)
                              }
                              value={associateAgentOptions?.find(
                                (elem) =>
                                  elem.value == values.associate_agent_id
                              )}
                            />

                            {values.who_brought_player1 == 1 && (
                              <ErrorMessage
                                name="associate_agent_id"
                                component="div"
                                className="text-danger"
                              />
                            )}
                          </Form.Group> */}

                            <Form.Group className=" form-inner">
                              <div>
                                <Select
                                  name="associate_agent_id"
                                  placeholder="Select"
                                  classNamePrefix="custom-select"
                                  options={customOptions}
                                  components={{
                                    DropdownIndicator: null,
                                  }}
                                  onChange={(e) =>
                                    handleSelectChange(e, setFieldValue)
                                  }
                                  onInputChange={handleInputChange}
                                  value={values?.associate_agent_id == "" ? "" : agentOptions?.find((elem) => elem.value == values.associate_agent_id )}
                                  isClearable
                                  formatOptionLabel={(data) => data.isAgentTeam ? (
                                      <div
                                        style={{ color: "#103D56", fontWeight: "bold", }}
                                        onClick={() => setIsModalOpen(true)}
                                      > + Add new Agent
                                      </div>
                                    ) : ( data.label )
                                  }
                                  noOptionsMessage={() => isAddingTeam ? (
                                      <div
                                        className="modal-add-teams"
                                        style={{
                                          color: "#103D56",
                                          fontWeight: "bold",
                                        }}
                                        onClick={() => setIsModalOpen(true)}
                                      >
                                        + Add new Agent
                                      </div>
                                    ) : (
                                      "No options found"
                                    )
                                  }
                                />

                                {/* Modal for Adding a New Team */}
                                <Modal
                                  isOpen={isModalOpen}
                                  onRequestClose={() => setIsModalOpen(false)}
                                  contentLabel="Add New Agent"
                                  style={{
                                    content: {
                                      top: "50%",
                                      left: "50%",
                                      right: "auto",
                                      bottom: "auto",
                                      marginRight: "-50%",
                                      transform: "translate(-50%, -50%)",
                                      width: "100%",
                                      maxWidth: "500px",
                                    },
                                  }}
                                >
                                  <div className="heding-add-new">
                                    <h3>Add New Agent</h3>
                                  </div>
                                  <div className="add-new-input">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter New Agent Name"
                                      value={newTeamName}
                                      onChange={(e) =>
                                        setNewTeamName(e.target.value)
                                      }
                                      onKeyPress={(event) => {
                                        const regex = /^[a-zA-Z\s]*$/;
                                        if (!regex.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onPaste={(event) => {
                                        const pastedData = event.clipboardData.getData('Text');
                                        const regex = /^[a-zA-Z\s]*$/;
                                        if (!regex.test(pastedData)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="add-team-btn ">
                                    <button
                                      className="add-modal-btn-ps" 
                                      onClick={() =>
                                        handleAddTeam(values, setFieldValue)
                                      }
                                    >
                                      Add
                                    </button>
                                    <button
                                      onClick={() => setIsModalOpen(false)}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </Modal>
                              </div>

                              <ErrorMessage
                                name="associate_agent_id"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="">
                      <div className="  mt-4">
                        <div className="d-flex justify-content-between">
                          <h3 className="h-20">Other Notes</h3>
                        </div>
                        <div className="cmn-form-fields">
                          <Row>
                            <Col lg={12} className="mt-2">
                              <Form.Group className="mb-3 form-inner">
                                <Field
                                  as="textarea"
                                  name="agent_other_notes"
                                  rows={3}
                                  className="form-control"
                                  placeholder="Write notes here"
                                />

                                {/* <ErrorMessage
                                name="agent_other_notes"
                                component="div"
                                className="text-danger"
                              /> */}
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-5">
                      <div className="d-flex">
                        <div className="mt-4 cmn-light-btn ">

                          <button
                            className="button"
                            type="button"
                            onClick={handleBack}
                          >
                            Back
                          </button>
                        </div>

                      </div>
                      <div className="d-flex">
                        <div className="mt-4 me-2 cmn-light-btn save-and-close-btn">
                          <button
                            type="button"
                            onClick={() =>
                              handleSaveAndClose(values, validateForm)
                            }
                            className="next-btn-ps"
                          >
                            Save & Close
                          </button>
                        </div>
                        <div className="mt-4 cmn-light-btn">
                          <button type="submit" className="next-btn-ps">
                            {" "}
                            Save & Next
                          </button>
                        </div>
                      </div>
                    </div>

                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
