import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import "react-calendar/dist/Calendar.css";
import {
  getPlayerDataById,
  getPlayerListAction,
} from "../Redux/Action/playerAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { positions } from "../utils/rawjson";
import { Encryptedid } from "../utils/Becrypt";
import { capitalize } from "../utils/helper";
import Loader from "../components/Loader";

export default function MyPlayers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const playerListData = useSelector((e) => e.playerAuthData.playerList);
  const loader = useSelector((e) => e.playerAuthData.loading);

  useEffect(() => {
    dispatch(getPlayerListAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPlayerDataById({ playerId: "" }));
  }, [dispatch]);

  console.log(playerListData, "tryrty45645645");

  return (
    <LoginLayout>
      {loader && <Loader />}
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-3 d-flex justify-content-between align-items-center">
              <h2>My Players</h2>
            </div>
            <div className="">
              <Row>
                {playerListData?.length > 0 ? (
                  playerListData?.map((item, index) => (
                    <Col lg={4} key={index}>
                      <div
                        className="player-box d-flex justify-content-between"
                        onClick={() =>
                          navigate(`/player-profile/${Encryptedid(item?._id)}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex">
                          {item?.gender == 0 ? (
                            <img
                              src={
                                item?.profile_image
                                  ? `${process.env.REACT_APP_IMAGE_PATH}${item.profile_image}`
                                  : require("../Assets/images/player_Ai.png")
                              }
                              alt="Profile"
                            />
                          ) : (
                            <img
                              src={
                                item?.profile_image
                                  ? `${process.env.REACT_APP_IMAGE_PATH}${item.profile_image}`
                                  : require("../Assets/images/player-female.png")
                              }
                              alt="Profile"
                            />
                          )}
                          <div className="ms-3">
                            <h3>{capitalize(item?.full_name)}</h3>
                            <h5>{capitalize(item?.national_team)}</h5>
                            <p>
                              {item?.positions?.map((elem, i) => (
                                <span key={i}>
                                  {positions[elem]}
                                  {i < item.positions.length - 1 && ", "}
                                </span>
                              ))}
                            </p>
                          </div>
                        </div>

                        <div>
                          <p>{item?.unique_id.toUpperCase()}</p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <h4 className="nodata">No Player Data</h4>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
