import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer-outer-bg">
      <Container className="pb-3">
        <footer className="mt-4 spacing-add">
          <Row>
            <Col lg={6}>
              <div className="footer-left">
                <img
                  src={require("../Assets/images/footer-logo.svg").default}
                />
                <p className="mt-3">
                  Lorem Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem Ipsum has been industry’s standard text.
                </p>
                <div className="foot-images mt-4">
                  <Link to="#">
                    <img
                      src={
                        require("../Assets/images/facebook-icon.svg").default
                      }
                    />
                  </Link>
                  <Link to="#">
                    <img
                      src={require("../Assets/images/insta-icon.svg").default}
                    />
                  </Link>
                  <Link to="#">
                    <img src={require("../Assets/images/x-icon.svg").default} />
                  </Link>
                  <Link to="#">
                    <img
                      src={
                        require("../Assets/images/link-din-icon.svg").default
                      }
                    />
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="footer-left">
                <Row>
                  <Col lg={4}>
                    <div className="footer-links text-left">
                      <div className="fot-title">
                        <h3>Company</h3>
                      </div>
                      <ul>
                        <li>
                          <Link to="#">About Us</Link>
                        </li>
                        <li>
                          <Link to="#">Blog</Link>
                        </li>
                        <li>
                          <Link to="#">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="#">Join Us</Link>
                        </li>
                        <li>
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to="/terms-and-conditions">
                            Terms And Conditions
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="footer-links text-left">
                      <div className="fot-title">
                        <h3>Quick Links</h3>
                      </div>
                      <ul>
                        <li>
                          <Link to="/home">Home</Link>
                        </li>
                        <li>
                          <Link to="/compare-players">Compare</Link>
                        </li>
                        <li>
                          <Link to="/message">Message</Link>
                        </li>
                        <li>
                          <Link to="/calendor">Calender</Link>
                        </li>
                        <li>
                          <Link to="/my-profile">Profile</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="footer-links text-left">
                      <div className="fot-title">
                        <h3>Quick Links</h3>
                      </div>
                      <ul>
                        <li>
                          <Link to="/profile-info">Add Player</Link>
                        </li>
                        <li>
                          <Link to="#">Add Director</Link>
                        </li>
                        <li>
                          <Link to="#">Add Coaches</Link>
                        </li>
                        <li>
                          <Link to="#">Add Teams</Link>
                        </li>
                        <li>
                          <Link to="#">Add Associate Agent</Link>
                        </li>
                        <li>
                          <Link to="#">Add Other Associate</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="bottom-sec">
            <div className="rights-res">
              <p>
                <span>
                  <img
                    src={require("../Assets/images/foot-boot-icon.svg").default}
                  />
                </span>{" "}
                2024 socceragent
              </p>
            </div>
            <div className="app-store">
              <Link to="#">
                <img
                  src={
                    require("../Assets/images/google-play-store.svg").default
                  }
                />
              </Link>
              <Link to="#">
                <img
                  src={require("../Assets/images/app-store-icons.svg").default}
                />
              </Link>
            </div>
          </div>
        </footer>
      </Container>
    </div>
  );
}
