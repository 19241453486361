import React from "react";
import { Container } from "react-bootstrap";

const TermsAndConditions = () => {
  return (
    <>
      <div class="container">
        <div class="privacy_text">
          <h2>Terms and Conditions for Go Trainer App</h2>
          <h2>1. Introduction</h2>
          <p>
            Welcome to Go Trainer! These Terms and Conditions govern your use of
            the Go Trainer application ("App"), developed to connect members and
            trainers for fitness training services. By accessing or using the
            App, you agree to be bound by these Terms and Conditions. Please
            read them carefully before using the App.
          </p>

          <h2>2. User Responsibilities</h2>
          <h4>2.1 Use at Your Own Risk.</h4>
          <p>
            {" "}
            All interactions and activities carried out through the Go Trainer
            App are at your own responsibility. The App is a platform that
            facilitates connections between members and trainers. The App does
            not provide any fitness training services itself.
          </p>

          <h4>2.2 Compliance with UAE Law:</h4>
          <p>
            {" "}
            All users, both members and trainers, are expected to comply with
            the laws and regulations of the United Arab Emirates (UAE). Any
            illegal activity or conduct that violates UAE law is strictly
            prohibited.
          </p>

          <h4>2.3 Liability:</h4>
          <p>
            {" "}
            Users are solely responsible for their actions and any consequences
            thereof. Go Trainer is not liable for any damages, losses, or
            injuries that may occur as a result of using the App.
          </p>

          <h2>3. Privacy and Data Security:</h2>
          <h4>3.1 Data Collection and Use:</h4>

          <p>
            {" "}
            Go Trainer collects personal data to facilitate the user experience,
            including but not limited to names, contact information, and
            official photo IDs. This data is used solely within the context of
            the App and for the purposes for which it was collected.
          </p>

          <h4>3.2 Data Security</h4>

          <p>
            {" "}
            Go Trainer collects personal data to facilitate the user experience,
            including but not limited to names, contact information, and
            official photo IDs. This data is used solely within the context of
            the App and for the purposes for which it was collected.
          </p>

          <h4>3.3 User Responsibility for Data:</h4>

          <p>
            {" "}
            Go Trainer collects personal data to facilitate the user experience,
            including but not limited to names, contact information, and
            official photo IDs. This data is used solely within the context of
            the App and for the purposes for which it was collected.
          </p>

          <h2>4. Mandatory Photo ID Upload</h2>

          <h4>4.1 Identification Requirement:</h4>
          <p>
            Uploading an official photo ID is mandatory for member recognition
            within the Go Trainer App. This requirement helps ensure the safety
            and authenticity of users.
          </p>

          <h4>4.2 Privacy of Identification Information:</h4>
          <p>
            The photo ID uploaded will be stored securely and used solely for
            the purpose of verifying user identity. Go Trainer will not share
            this information with third parties without your consent, except as
            required by law.
          </p>

          <h2>5. Limitations and Disclaimers</h2>

          <h4>5.1 No Warranties:</h4>
          <p>
            The Go Trainer App is provided "as is" without warranties of any
            kind, either express or implied. We do not guarantee the accuracy,
            reliability, or completeness of any information provided by users or
            trainers on the App.
          </p>

          <h4>5.2 Third-Party Content:</h4>
          <p>
            The App may contain links to third-party websites or resources. Go
            Trainer is not responsible for the content or availability of these
            external sites and does not endorse or assume any responsibility for
            any third-party content.
          </p>

          <h2>6. Modifications to Terms and Conditions</h2>

          <p>
            Go Trainer reserves the right to modify these Terms and Conditions
            at any time. Any changes will be effective immediately upon posting.
            It is your responsibility to review these Terms and Conditions
            regularly. Your continued use of the App following any changes
            constitutes your acceptance of the new terms.
          </p>

          <h2>7. Termination</h2>

          <p>
            Go Trainer reserves the right to terminate or suspend your access to
            the App at its sole discretion, without notice, for conduct that
            violates these Terms and Conditions or is otherwise harmful to other
            users or the App's business interests
          </p>

          <h2>8. Governing Law and Dispute Resolution</h2>

          <p>
            These Terms and Conditions are governed by the laws of the United
            Arab Emirates. Any disputes arising out of or in connection with
            these Terms shall be subject to the exclusive jurisdiction of the
            courts of the UAE.
          </p>

          <h2>9. Contact Information</h2>

          <p>
            For any questions or concerns regarding these Terms and Conditions,
            please contact us at X
          </p>
          <h4>Go Trainer</h4>
          <p>Email:</p>
          <p> Phone:</p>
          <p> Address:</p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
