import React, { useEffect, useState } from "react";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import {
  Formik,
  Field,
  Form as FormikForm,
  ErrorMessage,
  FieldArray,
} from "formik";
import {
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ProgressBar from "../CommonModals/ProgressBar";
import DatePicker from "react-datepicker";
import moment from "moment";

const Statistics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const [inputType, setInputType] = useState("text");

  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  const validateForm = (values) => {
    const errors = {};

    // if (!values.total_appearence) {
    //   errors.total_appearence = "required";
    // } else if (values.total_appearence < 0) {
    //   errors.total_appearence = "Value cannot be negative";
    // }

    // if (!values.starting_eleven) {
    //   errors.starting_eleven = "required";
    // } else if (values.starting_eleven < 0) {
    //   errors.starting_eleven = "Value cannot be negative";
    // }

    // if (!values.enter_as_substitution) {
    //   errors.enter_as_substitution = "required";
    // } else if (values.enter_as_substitution < 0) {
    //   errors.enter_as_substitution = "Value cannot be negative";
    // }

    // if (!values.out_as_substitution) {
    //   errors.out_as_substitution = "required";
    // } else if (values.out_as_substitution < 0) {
    //   errors.out_as_substitution = "Value cannot be negative";
    // }

    // if (!values.goals) {
    //   errors.goals = "required";
    // } else if (values.goals < 0) {
    //   errors.goals = "Value cannot be negative";
    // }

    // if (!values.goals_cancelled) {
    //   errors.goals_cancelled = "required";
    // } else if (values.goals_cancelled < 0) {
    //   errors.goals_cancelled = "Value cannot be negative";
    // }

    // if (!values.clean_sheets) {
    //   errors.clean_sheets = "required";
    // } else if (values.clean_sheets < 0) {
    //   errors.clean_sheets = "Value cannot be negative";
    // }

    // if (!values.assists) {
    //   errors.assists = "required";
    // } else if (values.assists < 0) {
    //   errors.assists = "Value cannot be negative";
    // }

    // if (!values.red_card) {
    //   errors.red_card = "required";
    // } else if (values.red_card < 0) {
    //   errors.red_card = "Value cannot be negative";
    // }

    // if (!values.yellow_card) {
    //   errors.yellow_card = "required";
    // } else if (values.yellow_card < 0) {
    //   errors.yellow_card = "Value cannot be negative";
    // }

    // if (!values.transfer_market_link) {
    //   errors.transfer_market_link = "required";
    // } else if (!/^https?:\/\/.*$/.test(values.transfer_market_link)) {
    //   errors.transfer_market_link = "Invalid URL";
    // }

    if (values.is_current_injuries !== 0 && values.is_current_injuries !== 1) {
      errors.is_current_injuries = "Invalid value for current injuries";
    }

    if (values.is_current_injuries === 1) {
      if (
        !values.current_injuries_issues ||
        !values.current_injuries_issues.length
      ) {
        errors.current_injuries_issues = "required";
      } else {
        values.current_injuries_issues.forEach((issue, index) => {
          if (!issue) {
            errors.current_injuries_issues = {
              ...(errors.current_injuries_issues || {}),
              [index]: "required",
            };
          }
        });
      }

      if (!values.date_of_coming_back) {
        errors.date_of_coming_back = "required";
      }
    }

    return errors;
  };

  const initialValues = {
    total_appearence: playerData?.total_appearence || "",
    total_appearence_out_of: playerData?.total_appearence_out_of || "",
    starting_eleven: playerData?.starting_eleven || "",
    starting_eleven_out_of: playerData?.starting_eleven_out_of || "",
    enter_as_substitution: playerData?.enter_as_substitution || "",
    out_as_substitution: playerData?.out_as_substitution || "",
    goals: playerData?.goals || "",
    goals_cancelled: playerData?.goals_cancelled || "",
    clean_sheets: playerData?.clean_sheets || "",
    assists: playerData?.assists || "",
    red_card: playerData?.red_card || "",
    yellow_card: playerData?.yellow_card || "",
     transfer_market_link: playerData?.transfer_market_link || "",
    statistics_other_notes: playerData?.statistics_other_notes || "",

    // ====================================================================
    is_current_injuries: playerData?.is_current_injuries || 0,
    current_injuries_issues:
      playerData?.current_injuries_issues?.length > 0
        ? playerData?.current_injuries_issues?.map((elem) => elem)
        : [""],
    // date_of_coming_back: playerData?.date_of_coming_back?.split("T")[0] || "",
    date_of_coming_back: playerData?.date_of_coming_back
      ? moment(playerData.date_of_coming_back).format("DD/MM/YYYY")
      : "",

    past_injuries:
      playerData?.past_injuries?.length > 0
        ? playerData?.past_injuries?.map((elem) => elem)
        : [""],
    health_problems:
      playerData?.health_problems?.length > 0
        ? playerData?.health_problems?.map((elem) => elem)
        : [""],
    health_other_notes: playerData?.health_other_notes || ""
  };

  useEffect(() => {
    dispatch(getPlayerDataById({ id: paylerId }));
  }, [dispatch]);

  const fieldMapping = {
    goalsconceded: "goals_cancelled",
    cleansheets: "clean_sheets",
    redcard: "red_card",
    yellowcard: "yellow_card",
    enterassubstitution: "enter_as_substitution",
    outassubstitution: "out_as_substitution",
  };

  const handleBack = () => {
    navigate(`/contract/${Encryptedid(paylerId)}`);
  };

  const handleSaveAndClose = (values, validateForm) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all the required fields.");
        return;
      }
      let formData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (key === "date_of_coming_back") return;
        if (fieldMapping[key] && value !== "") {
          const mappedKey = fieldMapping[key];
          formData.append(mappedKey, value);
        } else if (
          !fieldMapping[key] &&
          value !== "" &&
          !Array.isArray(value)
        ) {
          formData.append(key, value);
        }
      });

      if (Array.isArray(values.current_injuries_issues)) {
        values.current_injuries_issues.forEach((issue, index) => {
          formData.append(`current_injuries_issues[${index}]`, issue);
        });
      }

      if (Array.isArray(values.past_injuries)) {
        values.past_injuries.forEach((injury, index) => {
          formData.append(`past_injuries[${index}]`, injury);
        });
      }

      if (Array.isArray(values.health_problems)) {
        values.health_problems.forEach((problem, index) => {
          formData.append(`health_problems[${index}]`, problem);
        });
      }

      // if (values.is_current_injuries !== 0) {
      //   formData.append("date_of_coming_back", values.date_of_coming_back);
      // }
      if (values.is_current_injuries !== 0) {
        if (values.date_of_coming_back) {
          values.date_of_coming_back = moment(
            values.date_of_coming_back,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
        }
        formData.append("date_of_coming_back", values.date_of_coming_back);
      }

      formData.append("playerId", paylerId);
      formData.append("is_profile_completed", 4);

      dispatch(editPlayerAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          navigate("/my-players");
        } else {
          toast.error(res?.payload?.message);
        }
      });
    });
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="">
            <ProgressBar currentStep={5} totalSteps={8} />
            <div className="steps-heading">
              <p>
                <span>Step 4:</span> Statistics & Health
              </p>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={validateForm}
              onSubmit={(values) => {
                console.log(values);

                let formData = new FormData();
                Object.entries(values).forEach(([key, value]) => {
                  if (key === "date_of_coming_back") return;
                  if (fieldMapping[key] && value !== "") {
                    const mappedKey = fieldMapping[key];
                    formData.append(mappedKey, value);
                  } else if (
                    !fieldMapping[key] &&
                    value !== "" &&
                    !Array.isArray(value)
                  ) {
                    formData.append(key, value);
                  }
                });

                // Handle arrays separately to avoid duplication
                if (Array.isArray(values.current_injuries_issues)) {
                  values.current_injuries_issues.forEach((issue, index) => {
                    formData.append(`current_injuries_issues[${index}]`, issue);
                  });
                }

                if (Array.isArray(values.past_injuries)) {
                  values.past_injuries.forEach((injury, index) => {
                    formData.append(`past_injuries[${index}]`, injury);
                  });
                }

                if (Array.isArray(values.health_problems)) {
                  values.health_problems.forEach((problem, index) => {
                    formData.append(`health_problems[${index}]`, problem);
                  });
                }

                // Handle specific conditions
                // if (values.is_current_injuries !== 0) {
                //   formData.append( "date_of_coming_back", values.date_of_coming_back);
                // }

                if (values.is_current_injuries !== 0) {
                  if (values.date_of_coming_back) {
                    values.date_of_coming_back = moment(
                      values.date_of_coming_back,
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DD");
                  }
                  formData.append(
                    "date_of_coming_back",
                    values.date_of_coming_back
                  );
                }
                formData.append("playerId", paylerId);
                formData.append("is_profile_completed", 4);

                dispatch(editPlayerAction(formData)).then((res) => {
                  if (res?.payload?.status === 200) {
                    navigate(
                      `/other-information/${Encryptedid(
                        res?.payload?.data?._id
                      )}`
                    );
                  } else {
                    toast.error(res?.payload?.message);
                  }
                });
              }}
            >
              {({ errors, touched, setFieldValue, values, validateForm }) => (
                <FormikForm>
                  <div className=" mt-4 bottom-lines pb-4">
                    {console.log(values, "a234234234234234")}
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">
                        Statistics on Current Football Session
                      </h3>
                    </div>

                    <div className="cmn-form-fields mt-2">
                      <Row>
                        <Col lg={6}>
                          <div className="common-shadow">
                            <div className="num-inputs">
                              <h3 className="total-app">Total Appearances</h3>
                              <Form.Group>
                                <Field
                                  type="number"
                                  placeholder="eg.10"
                                  name="total_appearence"
                                  className="form-control"
                                  min="0"
                                />
                                <ErrorMessage
                                  name="total_appearence"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                              <p className="mb-0  ">Out of</p>
                              <div className="d-flex align-items-center  out-of">
                                <Form.Group>
                                  <Field
                                    type="number"
                                    placeholder="eg.10"
                                    name="total_appearence_out_of"
                                    className="form-control"
                                    min="0"
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="common-shadow">
                            <div className="num-inputs">
                              <h3 className="total-app  ">Starting Eleven</h3>
                              <Form.Group>
                                <Field
                                  type="number"
                                  placeholder="eg.10"
                                  name="starting_eleven"
                                  className="form-control"
                                  min="0"
                                />
                                <ErrorMessage
                                  name="starting_eleven"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                              <p className="mb-0 ">Out of</p>
                              <div className="d-flex align-items-center out-of">
                                <Form.Group>
                                  <Field
                                    type="number"
                                    placeholder="eg.10"
                                    name="starting_eleven_out_of"
                                    className="form-control"
                                    min="0"
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <div className="grid-statistics-section">
                          {" "}
                          {[
                            "Enter as Substitution",
                            "Out as Substitution",
                            "Goals",
                            "Goals Conceded",
                            "Clean Sheets",
                            "Assists",
                            "Red Card",
                            "Yellow Card",
                          ].map((field, idx) => {
                            const fieldName = field
                              .toLowerCase()
                              .replace(/ /g, "");
                            const mappedKey =
                              fieldMapping[fieldName] || fieldName;

                            return (
                              <div className="mt-3" key={idx}>
                                <div className="profile-card">
                                  <div className="num-inputs outer">
                                    <Form.Group>
                                      <h3 className="h-20">{field}</h3>
                                      <Field
                                        type="number"
                                        // placeholder={field}
                                        placeholder="Enter"
                                        min="0"
                                        name={field
                                          .toLowerCase()
                                          .replace(/ /g, "")}
                                        className="form-control"
                                        value={values[mappedKey]}
                                        onChange={(e) =>
                                          setFieldValue(
                                            mappedKey,
                                            e.target.value
                                          )
                                        }
                                      />
                                      {/* <ErrorMessage
                                           name={ mappedKey }
                                           component="div"
                                           className="text-danger"
                                           /> */}
                                    </Form.Group>
                                  </div>


                                </div>
                              </div>
                            );
                          })}

                          <div className="mt-3">
                            <div className="num-inputs outer">
                            <h3  className=""> External References </h3>
                                <Form.Group controlId="formBasicEmail">
                              <div className="num-inputs ">
                                  <Field name="transfer_market_link">
                                    {({ field }) => (
                                      <Form.Control
                                        {...field}
                                        type="url"
                                        placeholder="Add Link"
                                        name="transfer_market_link"
                                        value={values.transfer_market_link}
                                      />
                                    )}
                                  </Field>
                              </div>
                                </Form.Group>

                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-3 mt-3 form-inner other-notes-box label-text-up">
                          <label className="mb-2">Other Notes</label>

                          <Field
                            as="textarea"
                            name="statistics_other_notes"
                            className="form-control"
                            placeholder="Other notes write here"
                            rows={3}
                          />

                        </div>


                      </Row>
                    </div>
                  </div>

                  {/* health */}

                  <div className="d-flex justify-content-between mt-3">
                    <h3 className="h-20">Health</h3>
                  </div>

                  <div className="">
                    <Row>
                      <div className="">
                        <div className="post-new-injuries mb-2  ">
                          <div className="d-flex justify-content-between">
                            <h3 className="health-sub-heading">
                              Current Injuries
                            </h3>
                          </div>
                          <div className="post-flex-ps mt-2">
                            <div className=" licensed-sec">
                              <h3>Yes</h3>
                              <div className="form-check form-switch">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="is_current_injuries"
                                  value={1}
                                  checked={values.is_current_injuries === 1}
                                  onChange={() =>
                                    setFieldValue("is_current_injuries", 1)
                                  }
                                  as="input"
                                />
                              </div>
                            </div>

                            <div className="  licensed-sec">
                              <h3>No</h3>
                              <div className="form-check form-switch">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="is_current_injuries"
                                  value={0}
                                  checked={values.is_current_injuries === 0}
                                  onChange={() =>
                                    setFieldValue("is_current_injuries", 0)
                                  }
                                  as="input"
                                />
                              </div>
                            </div>
                          </div>

                          {values.is_current_injuries === 1 && (
                            <>
                              <div className="col-lg-12 mt-2">
                                <FieldArray name="current_injuries_issues">
                                  {({ push, remove }) => (
                                    <>
                                      <div className="d-flex justify-content-end">
                                        {/* <h3 className="health-sub-heading">
                                          Current Injuries Issues
                                        </h3> */}
                                        <button
                                          type="button"
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          onClick={() => push("")}
                                        >
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                              fill="#4C8BB4"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                      {values.current_injuries_issues.map(
                                        (_, index) => (
                                          <Row key={index} className=" ">
                                            <Col lg={6}>
                                              <h3 className="health-sub-heading mb-2">
                                                Current Injuries Issues
                                              </h3>
                                              <Form.Group className="mb-3 form-inner">
                                                <Field
                                                  type="text"
                                                  name={`current_injuries_issues[${index}]`}
                                                  placeholder="State the actual injury"
                                                  className="form-control"
                                                />
                                                <ErrorMessage
                                                  name={`current_injuries_issues[${index}]`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </Form.Group>
                                            </Col>

                                            {index === 0 && (
                                              <Col lg={6}>
                                                <h3 className="health-sub-heading mb-2">
                                                  Date of Coming Back
                                                </h3>
                                                <Form.Group className="mb-3 form-inner">
                                                  {/* <Field
                                                    type={inputType}
                                                    name={`date_of_coming_back`}
                                                    placeholder="Date of coming back"
                                                    className="form-control"
                                                    onFocus={() =>
                                                      setInputType("date")
                                                    }
                                                    onBlur={() =>
                                                      setInputType("text")
                                                    }
                                                  /> */}

                                                  <DatePicker
                                                    id="date_of_coming_back"
                                                    name="date_of_coming_back"
                                                    selected={
                                                      values.date_of_coming_back
                                                        ? moment(
                                                          values.date_of_coming_back,
                                                          "DD/MM/YYYY"
                                                        ).toDate()
                                                        : null
                                                    }
                                                    onChange={(date) => {
                                                      if (date) {
                                                        const formattedDate =
                                                          moment(date).format(
                                                            "DD/MM/YYYY"
                                                          );
                                                        setFieldValue(
                                                          "date_of_coming_back",
                                                          formattedDate
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          "date_of_coming_back",
                                                          ""
                                                        );
                                                      }
                                                    }}
                                                    placeholderText="Select"
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    scrollableYearDropdown
                                                    className="custom-datepicker__input form-control"
                                                    calendarClassName="custom-datepicker"
                                                  />
                                                  <ErrorMessage
                                                    name="date_of_coming_back"
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </Form.Group>
                                              </Col>
                                            )}

                                            <Col lg={6}>
                                              {index !== 0 && (
                                                <button
                                                  type="button"
                                                  style={{
                                                    border: "none",
                                                    background: "none",
                                                  }}
                                                  onClick={() => remove(index)}
                                                >
                                                  <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 28 28"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      fill-rule="evenodd"
                                                      clip-rule="evenodd"
                                                      d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                      fill="#4C8BB4"
                                                    />
                                                  </svg>
                                                </button>
                                              )}
                                            </Col>
                                          </Row>
                                        )
                                      )}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="post-new-injuries">
                          <div className="col-lg-12   ">
                            <div className="d-flex justify-content-between"></div>
                            <FieldArray name="past_injuries">
                              {({ push, remove }) => (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <h3 className="health-sub-heading">
                                      Post Injuries
                                    </h3>
                                    <button
                                      className="mb-2"
                                      type="button"
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                      onClick={() => push("")}
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                          fill="#4C8BB4"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                  {values.past_injuries.map((_, index) => (
                                    <Row key={index}>
                                      <Col lg={12}>
                                        <Form.Group className="mb-2 form-inner">
                                          <Field
                                            type="text"
                                            name={`past_injuries[${index}]`}
                                            placeholder="State the actual injury"
                                            className="form-control"
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col lg={12} style={{
                                        textAlign: "right",
                                      }}>
                                        {index !== 0 && (
                                          <button
                                            type="button"
                                            style={{
                                              border: "none",
                                              background: "none",

                                            }}
                                            onClick={() => remove(index)}
                                          >
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 28 28"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                fill="#4C8BB4"
                                              />
                                            </svg>
                                          </button>
                                        )}
                                      </Col>
                                    </Row>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </div>

                          <div className="col-lg-12 mt-3">
                            <div className="d-flex justify-content-between"></div>
                            <FieldArray name="health_problems">
                              {({ push, remove }) => (
                                <>
                                  <div className="d-flex justify-content-between ">
                                    <h3 className="health-sub-heading">
                                      Health Problems
                                    </h3>
                                    <button
                                      type="button"
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                      onClick={() => push("")}
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                          fill="#4C8BB4"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                  {values.health_problems.map((_, index) => (
                                    <Row key={index} className="mt-2">
                                      <Col lg={12}>
                                        <Form.Group className="mb-3 form-inner">
                                          <Field
                                            type="text"
                                            name={`health_problems[${index}]`}
                                            placeholder="State the health problem"
                                            className="form-control"
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col lg={12} style={{
                                        textAlign: "right",
                                      }}>
                                        {index !== 0 && (
                                          <button
                                            type="button"
                                            style={{
                                              border: "none",
                                              background: "none",
                                            }}
                                            onClick={() => remove(index)}
                                          >
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 28 28"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                fill="#4C8BB4"
                                              />
                                            </svg>
                                          </button>
                                        )}
                                      </Col>
                                    </Row>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>

                  {/* <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Other Notes</h3>
                    </div>
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="mb-3 form-inner">
                          <Field
                            as="textarea"
                            name="health_other_notes"
                            rows={3}
                            placeholder="Write here"
                            className="form-control"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div> */}

                  {/* ================================================================================================================================================== */}

                  <div className=" mt-3">
                    <div className="d-flex justify-content-between label-text-up">
                      <label className="mb-2">Other Notes</label>
                    </div>
                    <div className="cmn-form-fields">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3 form-inner">
                            <Field
                              as="textarea"
                              name="health_other_notes"
                              rows={3}
                              placeholder="Write here"
                              className="form-control"
                            />

                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mb-5">
                    <div className="d-flex">
                      <div className="mt-4 cmn-light-btn ">
                        <button className="" type="button" onClick={handleBack}>
                          Back
                        </button>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mt-4 me-2 cmn-light-btn save-and-close-btn">
                        <button
                          type="button"
                          onClick={() =>
                            handleSaveAndClose(values, validateForm)
                          }
                          className="next-btn-ps"
                        >
                          Save & Close
                        </button>
                      </div>
                      <div className="mt-4 cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Next
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default Statistics;
