import React, { useEffect, useState } from "react";
import {
  Formik,
  Form as FormFormik,
  Field,
  FieldArray,
  ErrorMessage,
} from "formik";
import { Container, Row, Col, Form } from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import {
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import ProgressBar from "../CommonModals/ProgressBar";

export default function Health() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputType, setInputType] = useState("text");

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  console.log(playerData, "sdfsdfs435345345");

  const validate = (values) => {
    const errors = {};

    if (values.is_current_injuries !== 0 && values.is_current_injuries !== 1) {
      errors.is_current_injuries = "Invalid value for current injuries";
    }

    if (values.is_current_injuries === 1) {
      if (
        !values.current_injuries_issues ||
        !values.current_injuries_issues.length
      ) {
        errors.current_injuries_issues = "required";
      } else {
        values.current_injuries_issues.forEach((issue, index) => {
          if (!issue) {
            errors.current_injuries_issues = {
              ...(errors.current_injuries_issues || {}),
              [index]: "required",
            };
          }
        });
      }

      if (!values.date_of_coming_back) {
        errors.date_of_coming_back = "required";
      }
    }

    if (!values.past_injuries) {
      errors.past_injuries = "required";
    }

    if (!values.health_problems) {
      errors.health_problems = "required";
    }

    return errors;
  };

  const initialValues = {
    is_current_injuries: playerData?.is_current_injuries || 0,
    current_injuries_issues: playerData?.current_injuries_issues?.length > 0 ? playerData?.current_injuries_issues?.map((elem) => elem) : [""],
    date_of_coming_back: playerData?.date_of_coming_back?.split("T")[0] || "",
    past_injuries: playerData?.past_injuries?.length > 0 ? playerData?.past_injuries?.map((elem) => elem) : [""],
    health_problems: playerData?.health_problems?.length > 0 ? playerData?.health_problems?.map((elem) => elem) : [""],
    health_other_notes: playerData?.health_other_notes || "",
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    // formData.append("is_current_injuries", values.is_current_injuries);
    // formData.append("date_of_coming_back", values.date_of_coming_back);

    // values.current_injuries_issues.forEach((issue, index) => {
    //   formData.append(`current_injuries_issues[${index}]`, issue);
    // });

    formData.append("health_other_notes", values.health_other_notes);
    formData.append("is_current_injuries", values.is_current_injuries);

    if (values.is_current_injuries !== 0) {
      formData.append("date_of_coming_back", values.date_of_coming_back);

      values.current_injuries_issues.forEach((issue, index) => {
        formData.append(`current_injuries_issues[${index}]`, issue);
      });
    }

    values.past_injuries.forEach((injury, index) => {
      formData.append(`past_injuries[${index}]`, injury);
    });

    values.health_problems.forEach((problem, index) => {
      formData.append(`health_problems[${index}]`, problem);
    });

    formData.append("playerId", paylerId);
    formData.append("is_profile_completed", 6);

    dispatch(editPlayerAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        navigate(`/other-information/${Encryptedid(res?.payload?.data?._id)}`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getPlayerDataById({ id: paylerId }));
  }, [dispatch]);


  const handleBack = () => {
    navigate(`/statistics/${Encryptedid(paylerId)}`);
  }

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
          <ProgressBar currentStep={6} totalSteps={8} />
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Health</h2>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              // validatr={validate}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <FormFormik>

                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Current Injuries</h3>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <div className="licensed-sec">
                          <h3>Yes</h3>
                          <div className="form-check form-switch">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="is_current_injuries"
                              value={1}
                              checked={values.is_current_injuries === 1}
                              onChange={() =>
                                setFieldValue("is_current_injuries", 1)
                              }
                              as="input"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="licensed-sec">
                          <h3>No</h3>
                          <div className="form-check form-switch">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="is_current_injuries"
                              value={0}
                              checked={values.is_current_injuries === 0}
                              onChange={() =>
                                setFieldValue("is_current_injuries", 0)
                              }
                              as="input"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {values.is_current_injuries === 1 && (
                    <>
                      <div className="common-outer mt-4">
                        <FieldArray name="current_injuries_issues">
                          {({ push, remove }) => (
                            <>
                              <div className="d-flex justify-content-between">
                                <h3 className="h-20">
                                  Current Injuries Issues
                                </h3>
                                <button
                                  type="button"
                                  style={{ border: "none", background: "none" }}
                                  onClick={() => push("")}
                                >
                                  <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                      fill="#4C8BB4"
                                    />
                                  </svg>
                                </button>
                              </div>
                              {values.current_injuries_issues.map(
                                (_, index) => (
                                  <Row key={index} className="mt-2">
                                    <Col lg={4}>
                                      <Form.Group className="mb-3 form-inner">
                                        <Field
                                          type="text"
                                          name={`current_injuries_issues[${index}]`}
                                          placeholder="State the actual injury"
                                          className="form-control"
                                        />
                                        <ErrorMessage
                                          name={`current_injuries_issues[${index}]`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    {index === 0 && (
                                      <Col lg={4}>
                                        <Form.Group className="mb-3 form-inner">
                                          <Field
                                            type={inputType}
                                            name={`date_of_coming_back`}
                                            placeholder="Date of coming back"
                                            className="form-control"
                                            disabled={
                                              values.is_current_injuries === 0
                                            }
                                            onFocus={() => setInputType("date")}
                                            onBlur={() => setInputType("text")}
                                          />
                                          <ErrorMessage
                                            name="date_of_coming_back"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </Form.Group>
                                      </Col>
                                    )}
                                    <Col lg={4}>
                                      {index !== 0 && (
                                        <button
                                          type="button"
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          onClick={() => remove(index)}
                                        >
                                          <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                              fill="#4C8BB4"
                                            />
                                          </svg>
                                        </button>
                                      )}
                                    </Col>
                                  </Row>
                                )
                              )}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </>
                  )}

                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between"></div>
                    <FieldArray name="past_injuries">
                      {({ push, remove }) => (
                        <>
                          <div className="d-flex justify-content-between">
                            <h3 className="h-20">Post Injuries</h3>
                            <button
                              type="button"
                              style={{ border: "none", background: "none" }}
                              onClick={() => push("")}
                            >
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                  fill="#4C8BB4"
                                />
                              </svg>
                            </button>
                          </div>
                          {values.past_injuries.map((_, index) => (
                            <Row key={index} className="mt-2">
                              <Col lg={4}>
                                <Form.Group className="mb-3 form-inner">
                                  <Field
                                    type="text"
                                    name={`past_injuries[${index}]`}
                                    placeholder="State the actual injury"
                                    className="form-control"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={8}>
                                {index !== 0 && (
                                  <button
                                    type="button"
                                    style={{
                                      border: "none",
                                      background: "none",
                                    }}
                                    onClick={() => remove(index)}
                                  >
                                    <svg
                                      width="28"
                                      height="28"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                        fill="#4C8BB4"
                                      />
                                    </svg>
                                  </button>
                                )}
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>

                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between"></div>
                    <FieldArray name="health_problems">
                      {({ push, remove }) => (
                        <>
                          <div className="d-flex justify-content-between">
                            <h3 className="h-20">Health Problems</h3>
                            <button
                              type="button"
                              style={{ border: "none", background: "none" }}
                              onClick={() => push("")}
                            >
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                  fill="#4C8BB4"
                                />
                              </svg>
                            </button>
                          </div>
                          {values.health_problems.map((_, index) => (
                            <Row key={index} className="mt-2">
                              <Col lg={4}>
                                <Form.Group className="mb-3 form-inner">
                                  <Field
                                    type="text"
                                    name={`health_problems[${index}]`}
                                    placeholder="State the health problem"
                                    className="form-control"
                                  />
                                </Form.Group>
                              </Col>
                              {index !== 0 && (
                                <button
                                  type="button"
                                  style={{ border: "none", background: "none" }}
                                  onClick={() => remove(index)}
                                >
                                  <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                      fill="#4C8BB4"
                                    />
                                  </svg>
                                </button>
                              )}
                            </Row>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>

                  <div className="common-outer mt-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Other Notes</h3>
                    </div>
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="mb-3 form-inner">
                          <Field
                            as="textarea"
                            name="health_other_notes"
                            rows={3}
                            placeholder="Write here"
                            className="form-control"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  {/* <Col lg={12} className="mt-4 d-flex justify-content-end">
                    <div className="cmn-light-btn">
                    <button type="button" onClick={handleBack}>Back</button>
                      <button type="submit">Next</button>
                    </div>
                  </Col> */}

                  <div className="d-flex justify-content-end mb-5">
                    <div className="mt-4 ">
                      <div className="cmn-light-btn">
                        <button type="submit" className="next-btn-ps"> Save & Close </button>
                      </div>
                    </div>

                    <div className="mt-4 ">
                      <div className="cmn-light-btn">
                        <button type="submit" className="next-btn-ps"> Save & Next </button>
                      </div>
                    </div>
                  </div>
                </FormFormik>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
