import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <>
      <div class="container">
        <div class="privacy_text">
          <h2>Privacy Policy for Go Trainer App</h2>
          <h2>1. Introduction</h2>
          <p>
            Go Trainer ("we," "us," "our") is committed to protecting the
            personal data of our users and ensuring compliance with the UAE's
            data protection regulations, including Federal Decree-Law No. 45 of
            2021 on the Protection of Personal Data (PDPL). This Data Protection
            Policy explains how we collect, use, disclose, and safeguard your
            personal data when you use the Go Trainer App ("App"). By using the
            App, you consent to the data practices described in this policy.
          </p>

          <h2>2. Data Collection</h2>
          <h4>2.1 Types of Data Collected</h4>
          <p>
            {" "}
            <b>Personal Information:</b> This includes your name, email address,
            phone number, official photo ID, and any other identifying
            information you provide during registration or use of the App.{" "}
          </p>
          <p>
            <b>Usage Data: </b> Information about your interactions with the
            App, such as your IP address, device type, browser type, and usage
            patterns
          </p>

          <h4>2.2 Methods of Data Collection:</h4>
          <p>
            {" "}
            <b> Direct Collection:</b> We collect data directly from you when
            you register for an account, update your profile, or communicate
            with us.
          </p>
          <p>
            {" "}
            <b> Automated Collection:</b> Data is collected automatically as you
            interact with the App through cookies, analytics tools, and other
            tracking technologies.
          </p>

          <h2>3. Use of Data:</h2>
          <h4>3.1 Purpose of Data Collection:</h4>
          <p>To provide and maintain the App and its features. </p>
          <p>To improve the App's functionality and user experience. </p>
          <p>
            To communicate with users regarding updates, services, and support.
          </p>
          <p>
            {" "}
            To ensure the security of our services and protect against fraud.
          </p>
          <p>
            {" "}
            To comply with legal obligations and enforce our terms and policies.
          </p>

          <h4>3.2 Legal Basis for Processing</h4>

          <p>
            <b> Consent:</b> By providing your data, you consent to its use as
            outlined in this policy.
          </p>
          <p>
            <b> Contractual Necessity:</b> Processing is necessary for the
            performance of the services you request.
          </p>
          <p>
            <b> Legal Obligations:</b> Compliance with applicable laws and
            regulations.
          </p>
          <p>
            <b> Legitimate Interests:</b> For purposes such as improving our
            services and ensuring the security of our systems.
          </p>

          <h2>4. Data Sharing and Disclosure</h2>

          <h4>4.1 Third-Party Service Provid</h4>
          <p>
            We may share your personal data with third-party service providers
            who assist us in operating the App, conducting our business, or
            providing services to you. These providers are bound by
            confidentiality agreements and are not permitted to use your data
            for any other purposes.
          </p>

          <h4>4.2 Legal Requirements</h4>
          <p>
            We may disclose your personal data if required by law or in response
            to valid requests by public authorities (e.g., court orders or
            government agencies).
          </p>

          <h2>5. Data Security</h2>

          <p>
            We implement appropriate technical and organizational measures to
            protect your personal data against unauthorized access, disclosure,
            alteration, and destruction. These measures include encryption,
            secure servers, and access controls. However, no method of
            transmission over the internet or electronic storage is completely
            secure, and we cannot guarantee absolute security.
          </p>

          <h2>6. Data Retention</h2>

          <p>
            We retain your personal data only as long as necessary to fulfill
            the purposes for which it was collected or as required by law. Upon
            termination of your account or upon your request, we will securely
            delete or anonymize your personal data, unless retention is required
            by legal obligations
          </p>

          <h2>7. Your Rights</h2>
          <h4>7.1 Access and Correct</h4>
          <p>
            You have the right to access the personal data we hold about you and
            to request corrections if any data is inaccurate or incomplete.
          </p>
          <h4>7.2 Data Portability</h4>
          <p>
            You have the right to request a copy of your personal data in a
            structured, commonly used, and machine-readable format.
          </p>

          <h4>7.3 Erasure</h4>
          <p>
            You have the right to request the deletion of your personal data,
            subject to certain legal limitations.
          </p>

          <h4>7.4 Objection and Restriction</h4>
          <p>
            You have the right to object to the processing of your personal data
            or request restrictions on its use under certain conditions.
          </p>
          <p>
            To exercise these rights, please contact us at [Contact
            Information].
          </p>

          <h2>8. Changes to This Policy</h2>

          <p>
            We may update this Data Protection Policy from time to time. We will
            notify you of any significant changes by posting the new policy on
            the App and updating the "Effective Date" at the top of this
            document. Your continued use of the App after any changes indicates
            your acceptance of the new terms.
          </p>

          <h2>9. Contact Us</h2>

          <p>
            If you have any questions or concerns about this Data Protection
            Policy or our data practices, please contact us at:
          </p>

          <h4>Go Trainer</h4>
          <p>Email:</p>
          <p> Phone:</p>
          <p> Address:</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
