import React, { useEffect, useState } from "react";
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FieldArray,
} from "formik";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { staticDataAction } from "../Redux/Action/staticAction";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import {
  addNewTeam,
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import ProgressBar from "../CommonModals/ProgressBar";
import Loader from "../components/Loader";
import Modal from "react-modal"; // Import react-modal
import { getTeamListAction } from "../Redux/Action/teamAction";
import { getAgentListAction } from "../Redux/Action/agentAction";
import DatePicker from "react-datepicker";
import moment from "moment";

Modal.setAppElement("#root");

// const validateForm = (values) => {
//   const errors = {};

// if (!values.Bonuses) {
//   errors.Bonuses = "required";
// } else if (isNaN(values.Bonuses)) {
//   errors.Bonuses = "must be a number";
// }

// if (!values.joined_date) {
//   errors.joined_date = "required";
// } else if (isNaN(new Date(values.joined_date))) {
//   errors.joined_date = "must be a valid date";
// }

// if (!values.contract_start_date) {
//   errors.contract_start_date = "required";
// } else if (isNaN(new Date(values.contract_start_date))) {
//   errors.contract_start_date = "must be a valid date";
// }

// if (!values.contract_end_date) {
//   errors.contract_end_date = "required";
// } else if (isNaN(new Date(values.contract_end_date))) {
//   errors.contract_end_date = "must be a valid date";
// }

// if (!values.new_contract_team_asking_amount) {
//   errors.new_contract_team_asking_amount = "required";
// }

// if (!values.release_clouse) {
//   errors.release_clouse = "required";
// }

// if (!values.buyout_clouse) {
//   errors.buyout_clouse = "required";
// }

// if (!values.new_contract_annual_salary) {
//   errors.new_contract_annual_salary = "required";
// }

// if (!values.new_contract_duration) {
//   errors.new_contract_duration = "required";
// }

// if (!values.new_contract_team_asking_amount) {
//   errors.new_contract_team_asking_amount = "required";
// }

// return errors;
// };

const validate = (values) => {
  const errors = {};
  if (values.is_player_proposed) {
    const proposedTeamErrors = {};

    values.proposed_team?.forEach((team, index) => {
      const teamErrors = {};

      if (team.proposed_team_id === "") {
        teamErrors.proposed_team_id = "required";
      }
      // if (!team.proposed_team_country) {
      //   teamErrors.proposed_team_country = "required";
      // }
      // if (!team.proposed_team_league) {
      //   teamErrors.proposed_team_league = "required";
      // }

      if (
        team.is_agent_proposed_player_to_team !== 0 &&
        team.is_agent_proposed_player_to_team !== 1
      ) {
        teamErrors.is_agent_proposed_player_to_team = "required";
      }
      if (team.is_agent_proposed_player_to_team == 1) {
        if (team.proposed_associate_agent_id == "") {
          teamErrors.proposed_associate_agent_id = "required";
        }
      }

      if (Object.keys(teamErrors).length > 0) {
        proposedTeamErrors[index] = teamErrors;
      }
    });

    if (Object.keys(proposedTeamErrors).length > 0) {
      errors.proposed_team = proposedTeamErrors;
    }
  } else {
    // errors.is_player_proposed = "required";
  }

  if (values.is_team_interested_to_player) {
    values.interested_team?.forEach((team, index) => {
      if (team.interested_team_id === "") {
        if (!errors.interested_team) errors.interested_team = [];
        errors.interested_team[index] = {
          interested_team_id: "required",
        };
      }
      // if (!team.interested_team_country) {
      //   if (!errors.interested_team) errors.interested_team = [];
      //   errors.interested_team[index] = {
      //     ...errors.interested_team[index],
      //     interested_team_country: "required",
      //   };
      // }
      // if (!team.interested_team_league) {
      //   if (!errors.interested_team) errors.interested_team = [];
      //   errors.interested_team[index] = {
      //     ...errors.interested_team[index],
      //     interested_team_league: "required",
      //   };
      // }
    });
  } else {
    // errors.is_team_interested_to_player = "required";
  }

  return errors;
};

export default function Contract() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputType, setInputType] = useState("text");
  const [inputType1, setInputType1] = useState("text");
  const [inputType2, setInputType2] = useState("text");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenAgent, setIsModalOpenAgent] = useState(false);
  const [isModalOpenInterested, setIsModalOpenInterested] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");
  const [newAgentName, setNewAgentName] = useState("");
  const [newTeamNameInterested, setNewTeamNameInterested] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledInterested, setIsDisabledInterested] = useState(false);
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [isAddingAgent, setIsAddingAgent] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [isProposed, setIsProposed] = useState(false);
  const [isInterested, setIsInterested] = useState(false);

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );

  const playerDataLoadingState = useSelector((e) => e.playerAuthData.loading);
  const agentListingData = useSelector((e) => e.agentAuthData.agentListDetail);
  const playerData = useSelector((e) => e.playerAuthData.playerDetail);
  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);

  useEffect(() => {
    dispatch(staticDataAction());
  }, [dispatch]);

  const [teamOptions, setTeamOptions] = useState(
    teanListingData?.map((elem) => ({
      label: elem?.team_name,
      value: elem?._id,
    })) || []
  );

  useEffect(() => {
    const teamOptions = teanListingData?.map((elem) => ({
      label: elem?.team_name,
      value: elem?._id,
    }));

    setTeamOptions(teamOptions);
  }, [teanListingData]);

  const [agentOptions, setAgentOptions] = useState(
    agentListingData?.map((elem) => ({
      label: elem?.agent_name,
      value: elem?._id,
    })) || []
  );

  useEffect(() => {
    const agentOptions = agentListingData?.map((elem) => ({
      label: elem?.agent_name,
      value: elem?._id,
    }));

    setAgentOptions(agentOptions);
  }, [agentListingData]);

  const optionsCurrentSalary = staticListForPlayer?.current_contract?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const optionsClause = staticListForPlayer?.is_current_injuries?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsAskingAmount = staticListForPlayer?.asking_amount?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const optionsContractDuration = staticListForPlayer?.duration?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  // const associateAgentOptions = agentListingData?.map((elem) => ({
  //   label: elem?.agent_name,
  //   value: elem?._id,
  // }));

  // const teamOptions = teanListingData?.map((elem) => ({
  //   label: elem?.team_name,
  //   value: elem?._id,
  // }));

  const optionsCountry = staticListForPlayer?.place_of_birth?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsLeague = staticListForPlayer?.team_league?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  // const initialValues = {
  //   current_contract:
  //     optionsCurrentSalary?.find(
  //       (elem) => elem.value === playerData?.current_contract
  //     ) || "",
  //   new_contract_annual_salary:
  //     optionsAskingAmount?.find(
  //       (elem) => elem.value === playerData?.new_contract_annual_salary
  //     ) || "",
  //   Bonuses: playerData?.Bonuses || "",
  //   joined_date: playerData?.joined_date
  //     ? playerData?.joined_date.split("T")[0]
  //     : "",
  //   contract_start_date: playerData?.contract_start_date
  //     ? playerData?.contract_start_date.split("T")[0]
  //     : "",
  //   contract_end_date: playerData?.contract_end_date
  //     ? playerData?.contract_end_date.split("T")[0]
  //     : "",
  //   release_clouse:
  //     optionsClause?.find(
  //       (elem) => elem.value === playerData?.release_clouse
  //     ) || "",
  //   buyout_clouse:
  //     optionsClause?.find((elem) => elem.value === playerData?.buyout_clouse) ||
  //     "",

  //   current_contract_other_notes:
  //     playerData?.current_contract_other_notes || "",
  //   new_contract_other_notes: playerData?.new_contract_other_notes || "",
  //   new_contract_team_asking_amount:
  //     optionsAskingAmount?.find(
  //       (elem) => elem.value === playerData?.new_contract_team_asking_amount
  //     ) || "",
  //   new_contract_duration:
  //     optionsContractDuration?.find(
  //       (elem) => elem.value === playerData?.new_contract_duration
  //     ) || "",
  // };

  const initialValues = playerData
    ? {
      current_contract:
        optionsCurrentSalary?.find(
          (elem) => elem.value == playerData?.current_contract
        ) ?? null,
      new_contract_annual_salary:
        optionsCurrentSalary?.find(
          (elem) => elem.value == playerData?.new_contract_annual_salary
        ) || "",
      // Bonuses: playerData?.Bonuses || "",
      Bonuses:
        optionsClause?.find((elem) => elem.value == playerData?.Bonuses) ||
        "",
      // joined_date: playerData?.joined_date
      //   ? playerData?.joined_date.split("T")[0]
      //   : "",
      joined_date: playerData?.joined_date
        ? moment(playerData.joined_date).format("DD/MM/YYYY")
        : "",
      contract_start_date: playerData?.contract_start_date
        ? moment(playerData.contract_start_date).format("DD/MM/YYYY")
        : "",
      contract_end_date: playerData?.contract_end_date
        ? moment(playerData.contract_end_date).format("DD/MM/YYYY")
        : "",
      // contract_start_date: playerData?.contract_start_date
      //   ? playerData?.contract_start_date.split("T")[0]
      //   : "",
      // contract_end_date: playerData?.contract_end_date
      //   ? playerData?.contract_end_date.split("T")[0]
      //   : "",
      release_clouse:
        optionsClause?.find(
          (elem) => elem.value == playerData?.release_clouse
        ) || "",
      buyout_clouse:
        optionsClause?.find(
          (elem) => elem.value == playerData?.buyout_clouse
        ) || "",
      current_contract_other_notes:
        playerData?.current_contract_other_notes || "",
      new_contract_other_notes: playerData?.new_contract_other_notes || "",
      new_contract_team_asking_amount:
        optionsAskingAmount?.find(
          (elem) => elem.value == playerData?.new_contract_team_asking_amount
        ) || "",
      new_contract_duration:
        optionsContractDuration?.find(
          (elem) => elem.value == playerData?.new_contract_duration
        ) || "",

      // =============================== proposed============================

      is_player_proposed: playerData?.is_player_proposed ?? null,
     proposed_associate_agent_note : playerData?.proposed_associate_agent_note,
      proposed_team:
        playerData?.proposed_team?.length > 0
          ? playerData?.proposed_team
          : [
            {
              proposed_team_id: "",
              proposed_team_country: null,
              proposed_team_league: null,
              is_agent_proposed_player_to_team: null,
              proposed_associate_agent_id: "",
              // proposed_associate_agent_note: "",
            },
          ],

      is_team_interested_to_player:
        playerData?.is_team_interested_to_player || 0,
      interested_team:
        playerData?.interested_team?.length > 0
          ? playerData?.interested_team
          : [
            {
              interested_team_id: "",
              interested_team_country: "",
              interested_team_league: null,
              interested_team_notes: "",
            },
          ],
      // ================================================================================================
    }
    : {
      current_contract: "",
      new_contract_annual_salary: "",
      Bonuses: "",
      joined_date: "",
      contract_start_date: "",
      contract_end_date: "",
      release_clouse: "",
      buyout_clouse: "",
      proposed_associate_agent_note: "",
      current_contract_other_notes: "",
      new_contract_other_notes: "",
      new_contract_team_asking_amount: "",
      new_contract_duration: "",

      // ================================================================================================

      is_player_proposed: null,
      proposed_team: [
        {
          proposed_team_id: "",
          proposed_team_country: null,
          proposed_team_league: null,
          is_agent_proposed_player_to_team: null,
          proposed_associate_agent_id: "",
          // proposed_associate_agent_note: "",
        },
      ],

      is_team_interested_to_player: 0,
      interested_team: [
        {
          interested_team_id: "",
          interested_team_country: "",
          interested_team_league: null,
          interested_team_notes: "",
        },
      ],
    };

  useEffect(() => {
    dispatch(getPlayerDataById({ id: paylerId }));
    dispatch(getTeamListAction());
    dispatch(getAgentListAction());
  }, [dispatch]);

  const transformValues = (values) => {
    return {
      Bonuses: values.Bonuses ? values.Bonuses.value : "",
      release_clouse: values.release_clouse ? values.release_clouse.value : "",
      current_contract: values.current_contract?.value ?? "",
      buyout_clouse: values.buyout_clouse ? values.buyout_clouse?.value : "",
      new_contract_annual_salary:
        values.new_contract_annual_salary?.value || "",
      new_contract_duration: values.new_contract_duration?.value || "",
      new_contract_team_asking_amount:
        values.new_contract_team_asking_amount?.value || "",
    };
  };

  const handleBack = () => {
    navigate(`/agent-relationship/${Encryptedid(paylerId)}`);
  };

  const handleAddTeam = (values, setFieldValue, index) => {
    if (!newTeamName.trim()) {
      toast.error("Team name cannot be empty.");
      return;
    }

    dispatch(addNewTeam({ name: newTeamName, type: 1 })).then((res) => {
      if (res?.payload?.status === 200) {
        const newTeamId = res?.payload?.data?._id;
        const newTeam = { label: newTeamName, value: newTeamId };

        setTeamOptions([...teamOptions, newTeam]);
        setFieldValue(
          `proposed_team.${index}.proposed_team_id`,
          newTeam ? newTeam.value : ""
        );

        setIsDisabled(true);
        setIsModalOpen(false);
        setNewTeamName("");
      } else {
        toast.error("Failed to add the team. Please try again.");
      }
    });
  };

  const handleAddTeamIntrested = (values, setFieldValue, index) => {
    if (!newTeamNameInterested.trim()) {
      toast.error("Team name cannot be empty.");
      return;
    }

    dispatch(addNewTeam({ name: newTeamNameInterested, type: 1 })).then(
      (res) => {
        if (res?.payload?.status === 200) {
          const newTeamId = res?.payload?.data?._id;
          const newTeam = { label: newTeamNameInterested, value: newTeamId };

          setTeamOptions([...teamOptions, newTeam]);
          setFieldValue(
            `interested_team.${index}.interested_team_id`,
            newTeam ? newTeam.value : ""
          );

          setIsDisabledInterested(true);
          setIsModalOpenInterested(false);
          setNewTeamNameInterested("");
        } else {
          toast.error("Failed to add the team. Please try again.");
        }
      }
    );
  };

  // const handleInputChange = (input) => {
  //   const isTeamExists = teamOptions.some((team) =>
  //     team.label.toLowerCase().includes(input.toLowerCase())
  //   );
  //   setIsAddingTeam(!isTeamExists);
  // };

  const handleInputChange = (input) => {
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
    const isTeamExists = teamOptions?.some((team) =>
      team?.label?.toLowerCase()?.includes(filteredInput.toLowerCase())
    );
    setIsAddingTeam(!isTeamExists);
    return filteredInput;
  };

  // const handleInputChangeAgent = (input) => {
  //   const isTeamExists = agentOptions.some((agent) =>
  //     agent.label.toLowerCase().includes(input.toLowerCase())
  //   );
  //   setIsAddingAgent(!isTeamExists);
  // };

  const handleInputChangeAgent = (input) => {
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
    const isTeamExists = agentOptions?.some((agent) =>
      agent.label.toLowerCase()?.includes(filteredInput.toLowerCase())
    );
    setIsAddingAgent(!isTeamExists);
    return filteredInput;
  };

  // const customOptions = isAddingTeam
  //   ? [{ label: "Add Team", value: "add-team", isAddTeam: true }]
  //   : teamOptions;

  // const customOptionsAgent = isAddingAgent
  //   ? [{ label: "Add Agent", value: "add-agent", isAddingAgent: true }]
  //   : agentOptions;

  const customOptions = [
    { label: "+ Add new Team", value: "add-team", isAddTeam: true },
    ...teamOptions,
  ];

  const customOptionsAgent = [
    { label: "+ Add new Agent", value: "add-agent", isAddingAgent: true },
    ...agentOptions,
  ];

  const handleSelectChange = (option, setFieldValue, index) => {
    if (option?.isAddTeam) {
      setIsModalOpen(true);
    } else {
      const data = teanListingData?.find((ele) => ele?._id === option?.value);
      setFieldValue(
        `proposed_team.${index}.proposed_team_league`,
        data ? data?.team_league : ""
      );
      setFieldValue(
        `proposed_team.${index}.proposed_team_country`,
        data ? data?.team_country : ""
      );
      setFieldValue(
        `proposed_team.${index}.proposed_team_id`,
        option ? option.value : ""
      );
    }
  };

  const handleSelectInterestedChange = (option, setFieldValue, index) => {
    if (option?.isAddTeam) {
      setIsModalOpenInterested(true);
    } else {
      const data = teanListingData?.find((ele) => ele?._id === option?.value);
      setFieldValue(
        `interested_team.${index}.interested_team_league`,
        data ? data?.team_league : ""
      );
      setFieldValue(
        `interested_team.${index}.interested_team_country`,
        data ? data?.team_country : ""
      );
      setFieldValue(
        `interested_team.${index}.interested_team_id`,
        option ? option.value : ""
      );
    }
  };

  const handleSelectChangeAgent = (option, setFieldValue, index) => {
    if (option?.isAddingAgent) {
      setIsModalOpenAgent(true);
    } else {
      const data = agentOptions?.find((ele) => ele?.value === option?.value);
      setFieldValue(
        `proposed_team.${index}.proposed_associate_agent_id`,
        data ? data?.value : ""
      );
    }
  };

  const handleAddAgent = (values, setFieldValue, index) => {
    if (!newAgentName.trim()) {
      toast.error("Agent name cannot be empty.");
      return;
    }

    // Dispatch API call to add the new team
    dispatch(addNewTeam({ name: newAgentName, type: 0 })).then((res) => {
      if (res?.payload?.status === 200) {
        const newAgentId = res?.payload?.data?._id;
        const newAgent = { label: newAgentName, value: newAgentId };

        setAgentOptions([...agentOptions, newAgent]);
        setFieldValue(
          `proposed_team.${index}.proposed_associate_agent_id`,
          newAgent ? newAgent.value : ""
        );
        setIsModalOpenAgent(false);
        setNewAgentName("");
      } else {
        toast.error("Failed to add the team. Please try again.");
      }
    });
  };

  const handleSaveAndClose = (values, validateForm) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all the required fields.");
        return;
      }

      if (values.joined_date) {
        values.joined_date = moment(values.joined_date, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
      }

      if (values.contract_start_date) {
        values.contract_start_date = moment(
          values.contract_start_date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }

      if (values.contract_end_date) {
        values.contract_end_date = moment(
          values.contract_end_date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }

      const transformedValues = transformValues(values);
      let formData = new FormData();

      // Add non-object fields to formData
      for (const key in transformedValues) {
        if (
          transformedValues.hasOwnProperty(key) &&
          (typeof transformedValues[key] !== "object" ||
            Array.isArray(transformedValues[key]))
        ) {
          formData.append(key, transformedValues[key]);
        }
      }

      // Handle proposed_team if needed
      if (
        values.is_player_proposed == 1 &&
        Array.isArray(values.proposed_team)
      ) {
        formData.append(
          "proposed_team",
          JSON.stringify(
            values.proposed_team.map((team) => {
              const teamData = {
                proposed_team_id: team.proposed_team_id,
                proposed_team_country: team.proposed_team_country,
                proposed_team_league: team.proposed_team_league,
                is_agent_proposed_player_to_team:
                  team.is_agent_proposed_player_to_team,
                // proposed_associate_agent_note:
                //   team.proposed_associate_agent_note,
              };

              if (team.is_agent_proposed_player_to_team !== 0) {
                teamData.proposed_associate_agent_id =
                  team.proposed_associate_agent_id;
              }
              return teamData;
            })
          )
        );
      }

      // Handle interested_team if needed
      if (
        values.is_team_interested_to_player == 1 &&
        Array.isArray(values.interested_team)
      ) {
        formData.append(
          "interested_team",
          JSON.stringify(
            values.interested_team.map((team) => ({
              interested_team_id: team.interested_team_id,
              interested_team_country: team.interested_team_country,
              interested_team_league: team.interested_team_league,
              interested_team_notes: team.interested_team_notes,
            }))
          )
        );
      }

      formData.append("playerId", paylerId);
      formData.append("is_profile_completed", 3);

      dispatch(editPlayerAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          navigate("/my-players");
        } else {
          toast.error(res?.payload?.message);
        }
      });
    });
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="">
            <ProgressBar currentStep={3} totalSteps={8} />
            <div className="steps-heading">
              <p>
                <span>Step 3:</span> Contract & Proposals
              </p>
            </div>
            <div className=" mt-4">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={validate}
                onSubmit={(values) => {
                  const transformedValues = transformValues(values);

                  if (values.joined_date) {
                    values.joined_date = moment(
                      values.joined_date,
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DD");
                  }

                  if (values.contract_start_date) {
                    values.contract_start_date = moment(
                      values.contract_start_date,
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DD");
                  }

                  if (values.contract_end_date) {
                    values.contract_end_date = moment(
                      values.contract_end_date,
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DD");
                  }

                  let formData = new FormData();

                  for (const key in transformedValues) {
                    if (transformedValues.hasOwnProperty(key)) {
                      // Check if the value is an object and skip it
                      if (
                        typeof transformedValues[key] === "object" &&
                        !Array.isArray(transformedValues[key])
                      ) {
                        continue;
                      }
                      formData.append(key, transformedValues[key]);
                    }
                  }

                  Object.entries(values).forEach(([key, value]) => {
                    if (
                      key !== "release_clouse" &&
                      key !== "current_contract" &&
                      key !== "buyout_clouse" &&
                      key !== "new_contract_annual_salary" &&
                      key !== "new_contract_duration" &&
                      key !== "new_contract_team_asking_amount" &&
                      key !== "proposed_team" &&
                      key !== "interested_team" &&
                      key !== "Bonuses" &&
                      // key !== "proposed_associate_agent_note" &&
                      (typeof value !== "object" || Array.isArray(value)) // Skip if it's an object but not an array
                    ) {
                      formData.append(key, value);
                    }
                  });

                  // Handle proposed_team if needed
                  if (values.is_player_proposed == 1) {
                    formData.append(
                      "proposed_team",
                      JSON.stringify(
                        values.proposed_team.map((team) => {
                          const teamData = {
                            proposed_team_id: team.proposed_team_id,
                            proposed_team_country: team.proposed_team_country,
                            proposed_team_league: team.proposed_team_league,
                            is_agent_proposed_player_to_team:
                              team.is_agent_proposed_player_to_team,
                            // proposed_associate_agent_note:
                            //   team.proposed_associate_agent_note,
                          };

                          if (team.is_agent_proposed_player_to_team !== 0) {
                            teamData.proposed_associate_agent_id =
                              team.proposed_associate_agent_id;
                          }
                          return teamData;
                        })
                      )
                    );
                  }

                  // Handle interested_team if needed
                  if (values.is_team_interested_to_player == 1) {
                    formData.append(
                      "interested_team",
                      JSON.stringify(
                        values.interested_team.map((team) => ({
                          interested_team_id: team.interested_team_id,
                          interested_team_country: team.interested_team_country,
                          interested_team_league: team.interested_team_league,
                          interested_team_notes: team.interested_team_notes,
                        }))
                      )
                    );
                  }

                  // Append other necessary data
                  formData.append("playerId", paylerId);
                  formData.append("is_profile_completed", 3);

                  dispatch(editPlayerAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/statistics/${Encryptedid(res?.payload?.data?._id)}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                }}
              >
                {({ setFieldValue, values, touched, errors, validateForm }) => (
                  <FormikForm>
                    {playerDataLoadingState && <Loader />}
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Current Contract Details</h3>
                    </div>
                    <div className="cmn-form-fields mt-2 bottom-lines">
                      <Row>
                        <div className="grid-section new-grid-system">
                          <div className="profile-card mb-3 label-text-up">
                            <label className="mb-2">
                              Current Annual Salary
                            </label>
                            <Select
                              placeholder="Select"
                              name="current_contract"
                              classNamePrefix="custom-select"
                              options={optionsCurrentSalary}
                              onChange={(option) =>
                                setFieldValue("current_contract", option)
                              }
                              value={values.current_contract}
                            />
                            <ErrorMessage
                              name="current_contract"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="profile-card mb-3 label-text-up">
                            <label className="mb-2">Bonuses</label>

                            {/* <Field
                              type="number"
                              name="Bonuses"
                              className="form-control"
                              placeholder="Select"
                            /> */}

                            <Select
                              name="Bonuses"
                              options={optionsClause}
                              placeholder="Select"
                              classNamePrefix="custom-select"
                              onChange={(option) =>
                                setFieldValue("Bonuses", option)
                              }
                              value={values.Bonuses || ""}
                            // value={optionsClause?.find(
                            //   (opt) => opt.value === values.release_clouse
                            // )}
                            />
                            <ErrorMessage
                              name="Bonuses"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="form-group mb-3 form-inner add-new-date-icon label-text-up">
                            <label className="mb-2">Joined Date</label>

                            {/* <Field
                              type={inputType}
                              name="joined_date"
                              className="form-control"
                              placeholder="Select"
                              onFocus={() => setInputType("date")}
                              onBlur={() => setInputType("text")}
                              id="date"
                            /> */}
                            <DatePicker
                              id="joined_date"
                              name="joined_date"
                              selected={
                                values.joined_date
                                  ? moment(
                                    values.joined_date,
                                    "DD/MM/YYYY"
                                  ).toDate()
                                  : null
                              }
                              // onChange={(date) => {
                              //   const formattedDate = moment(date).format('DD/MM/YYYY');
                              //   setFieldValue("joined_date", formattedDate);
                              // }}

                              onChange={(date) => {
                                if (date) {
                                  const formattedDate =
                                    moment(date).format("DD/MM/YYYY");
                                  setFieldValue("joined_date", formattedDate);
                                } else {
                                  setFieldValue("joined_date", "");
                                }
                              }}
                              placeholderText="Select"
                              dateFormat="dd/MM/yyyy"
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode="select"
                              scrollableYearDropdown
                              className="custom-datepicker__input form-control"
                              calendarClassName="custom-datepicker"
                            />
                            <ErrorMessage
                              name="joined_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="form-group mb-3 form-inner add-new-date-icon label-text-up">
                            <label className="mb-2">Contract Start Date</label>

                            {/* <Field
                              type={inputType1}
                              name="contract_start_date"
                              className="form-control"
                              placeholder="Select"
                              onFocus={() => setInputType1("date")}
                              onBlur={() => setInputType1("text")}
                            /> */}

                            <DatePicker
                              id="contract_start_date"
                              name="contract_start_date"
                              selected={
                                values.contract_start_date
                                  ? moment(
                                    values.contract_start_date,
                                    "DD/MM/YYYY"
                                  ).toDate()
                                  : null
                              }
                              onChange={(date) => {
                                if (date) {
                                  const formattedDate =
                                    moment(date).format("DD/MM/YYYY");
                                  setFieldValue(
                                    "contract_start_date",
                                    formattedDate
                                  );
                                } else {
                                  setFieldValue("contract_start_date", "");
                                }
                              }}
                              placeholderText="Select"
                              dateFormat="dd/MM/yyyy"
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode="select"
                              scrollableYearDropdown
                              className="custom-datepicker__input form-control"
                              calendarClassName="custom-datepicker"
                            />
                            <ErrorMessage
                              name="contract_start_date"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        {/* <Select
                              placeholder="Current Annual Salary"
                              name="current_contract"
                              options={optionsCurrentSalary}
                              onChange={(option) =>
                                setFieldValue("current_contract", option)
                              }
                              value={values.current_contract || ""}
                            /> */}
                        <div className="">
                          <div className="buyout-ps">
                            <div className="form-group mb-3 form-inner add-new-date-icon full-width label-text-up">
                              <label className="mb-2">Contract End Date</label>
                              {/* 
                              <Field
                                name="contract_end_date"
                                type={inputType2}
                                className="form-control"
                                placeholder="Select"
                                onFocus={() => setInputType2("date")}
                                onBlur={() => setInputType2("text")}
                              /> */}

                              <DatePicker
                                id="contract_end_date"
                                name="contract_end_date"
                                selected={
                                  values.contract_end_date
                                    ? moment(
                                      values.contract_end_date,
                                      "DD/MM/YYYY"
                                    ).toDate()
                                    : null
                                }
                                onChange={(date) => {
                                  if (date) {
                                    const formattedDate =
                                      moment(date).format("DD/MM/YYYY");
                                    setFieldValue(
                                      "contract_end_date",
                                      formattedDate
                                    );
                                  } else {
                                    setFieldValue("contract_end_date", "");
                                  }
                                }}
                                placeholderText="Select"
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                scrollableYearDropdown
                                className="custom-datepicker__input form-control"
                                calendarClassName="custom-datepicker"
                              />
                              <ErrorMessage
                                name="contract_end_date"
                                component="div"
                                className="text-danger"
                              />
                            </div>

                            <div className="profile-card full-width mb-3 label-text-up">
                              <label className="mb-2">Release Clauses</label>

                              <Select
                                name="release_clouse"
                                options={optionsClause}
                                placeholder="Select"
                                classNamePrefix="custom-select"
                                onChange={(option) =>
                                  setFieldValue("release_clouse", option)
                                }
                                value={values.release_clouse || ""}
                              // value={optionsClause?.find(
                              //   (opt) => opt.value === values.release_clouse
                              // )}
                              />
                              <ErrorMessage
                                name="release_clouse"
                                component="div"
                                className="text-danger"
                              />
                            </div>

                            <div className="profile-card full-width mb-3 label-text-up">
                              <label className="mb-2">Buyout Clause</label>

                              <Select
                                name="buyout_clouse"
                                options={optionsClause}
                                placeholder="Select"
                                classNamePrefix="custom-select"
                                onChange={(option) =>
                                  setFieldValue("buyout_clouse", option)
                                }
                                value={values.buyout_clouse}
                              // value={options.buyout_clouse.find(
                              //   (opt) => opt.value === values.buyout_clouse
                              // )}
                              />
                              <ErrorMessage
                                name="buyout_clouse"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>


                        <div className="form-group mb-3  form-inner other-notes-box label-text-up">
                          <label className="mb-2">Other Notes</label>

                          <Field
                            as="textarea"
                            name="current_contract_other_notes"
                            className="form-control"
                            placeholder="Other notes write here"
                            rows={3}
                          />

                        </div>
                      </Row>
                    </div>

                    <div className=" mt-4 bottom-lines">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">
                          Player's Requirement for new contract
                        </h3>
                      </div>
                      <Row>
                        <div className="grid-section-player">
                          <div className="profile-card mb-3 label-text-up">
                            <label className="mb-2">
                              Annual Asking Salary for New Contract
                            </label>
                            <Select
                              options={optionsCurrentSalary}
                              name="new_contract_annual_salary"
                              placeholder="Select"
                              classNamePrefix="custom-select"
                              onChange={(option) =>
                                setFieldValue(
                                  "new_contract_annual_salary",
                                  option
                                )
                              }
                              value={values.new_contract_annual_salary}
                            // value={options.new_contract_annual_salary.find(
                            //   (opt) => opt.value === values.new_contract_annual_salary
                            // )}
                            />
                            <ErrorMessage
                              name="new_contract_annual_salary"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="profile-card mb-3 label-text-up">
                            <label className="mb-2">
                              Contract Duration Requirements
                            </label>
                            <Select
                              options={optionsContractDuration}
                              name="new_contract_duration"
                              placeholder="Select"
                              classNamePrefix="custom-select"
                              onChange={(option) =>
                                setFieldValue("new_contract_duration", option)
                              }
                              value={values.new_contract_duration}
                            // value={options.new_contract_duration.find(
                            //   (opt) => opt.value === values.new_contract_duration
                            // )}
                            />
                            <ErrorMessage
                              name="new_contract_duration"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="profile-card mb-3 label-text-up">
                            {/* <Link to="#">
                            <h3>Team’s Asking Amount</h3>
                            <img
                              src={require("../Assets/images/arrowdown.svg").default}
                              alt="Arrow Down"
                            />
                          </Link>
                        </div> */}
                            <label className="mb-2">
                              Team’s Asking Amount for Transfer
                            </label>
                            <Select
                              placeholder="Select"
                              classNamePrefix="custom-select"
                              name="new_contract_team_asking_amount"
                              options={optionsAskingAmount}
                              onChange={(option) =>
                                setFieldValue(
                                  "new_contract_team_asking_amount",
                                  option
                                )
                              }
                              value={values.new_contract_team_asking_amount}
                            // value={optionsAskingAmount.find(
                            //   (opt) => opt.value === values.new_contract_team_asking_amount
                            // )}
                            />
                            <ErrorMessage
                              name="new_contract_team_asking_amount"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          {/* <div className="form-group mb-3 form-inner last-notes-box">
                            <label className="mb-2">Other Notes</label>
                            <Field
                              type="text"
                              name="new_contract_other_notes"
                              className="form-control"
                              placeholder="Other notes write here"
                            />
                            <ErrorMessage
                              name="new_contract_other_notes"
                              component="div"
                              className="text-danger"
                            />
                          </div> */}
                        </div>

                        <div className="form-group mb-3 form-inner other-notes-box label-text-up">
                          <label className="mb-2">Other Notes</label>

                          <Field
                            as="textarea"
                            name="new_contract_other_notes"
                            className="form-control"
                            placeholder="Other notes write here"
                            rows={3}
                          />

                        </div>
                      </Row>
                    </div>

                    {/* ===================================Proposals================================================== */}

                    <div className="pro-posals-ps pb-4">
                      <div className="pro-posals-left mt-4">
                        <div className="d-flex justify-content-between">
                          <h3 className="h-20">Proposal’s</h3>
                        </div>
                        <div className="cmn-form-fields mt-2">
                          <Row>
                            <div className="label-text-up">
                              <label className="mb-2">
                                Teams that the player has been proposed
                              </label>
                              <div className="licensed-sec">
                                <h3>Has the player been proposed to team</h3>
                                <div className="form-check form-switch">
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    name="is_player_proposed"
                                    checked={values.is_player_proposed === 1}
                                    onChange={() => {
                                      const newValue =
                                        values.is_player_proposed === 1 ? 0 : 1;
                                      setFieldValue(
                                        "is_player_proposed",
                                        newValue
                                      );
                                      setIsProposed(newValue === 1);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Row>

                          <ErrorMessage
                            component="div"
                            name="is_player_proposed"
                            className="text-danger"
                          />

                          {values.is_player_proposed ? (
                            <FieldArray name="proposed_team">
                              {({ push, remove }) => (
                                <div className="mt-3">
                                  <div className="d-flex justify-content-between">
                                    {/* <h3 className="h-20 mt-2">Teams</h3> */}
                                    <button
                                      type="button"
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                      onClick={() =>
                                        push({
                                          proposed_team_id: null,
                                          proposed_team_country: null,
                                          proposed_team_league: null,
                                          is_agent_proposed_player_to_team:
                                            null,
                                          // is_agent_proposed_player_to_team: 1,
                                          proposed_associate_agent_id: "",
                                          // proposed_associate_agent_note: "",
                                        })
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433289 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091V14.5455C10.9091 14.7866 10.8133 15.0178 10.6428 15.1883C10.4723 15.3588 10.2411 15.4545 10 15.4545C9.7589 15.4545 9.52767 15.3588 9.35718 15.1883C9.18669 15.0178 9.09091 14.7866 9.09091 14.5455V10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091V5.45454C9.09091 5.21344 9.18669 4.98221 9.35718 4.81172C9.52767 4.64123 9.7589 4.54545 10 4.54545C10.2411 4.54545 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 10.9091 5.21344 10.9091 5.45454V9.09091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                          fill="#103D56"
                                        />
                                      </svg>
                                    </button>
                                  </div>

                                  {values.proposed_team.map((team, index) => (
                                    <div
                                      key={index}
                                      className="cmn-form-fields mt-2"
                                    >
                                      <div className="d-flex justify-content-end mt-2 mb-2">
                                        {index > 0 && (
                                          <div className="d-flex justify-content-between">
                                            <button
                                              type="button"
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                              onClick={() => remove(index)}
                                            >
                                              <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51808 4.3459 0.761209 6.17316C0.0043329 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091H7.05411C7.05411 11.1502 11.0796 14.1381 10.9091 14.3086C8.95963 12.4289 8.69336 13.8086 10 15.4545C9.7589 15.4545 11.6021 13.1035 11.4316 12.933C11.2612 12.7625 10.6934 14.0477 10.6934 13.8066L9.09091 10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091L10.4316 6.80859C10.4316 6.56749 11.2612 6.97908 11.4316 6.80859C11.6021 6.63811 11.6905 6.30859 11.9316 6.30859C12.1727 6.30859 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 7.16322 8.8498 7.16322 9.09091H10.9091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                                  fill="#103D56"
                                                />
                                              </svg>
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                      <Row>
                                        <Col lg={4}>
                                          {/* <div className="form-group mb-3 form-inner">

                                            <Select
                                              className={`form-control`}
                                              // name={`proposed_team.${index}.proposed_team_id`}
                                              placeholder="Enter name"
                                              options={teamOptions}
                                              onChange={(option) =>
                                                setFieldValue(
                                                  `proposed_team.${index}.proposed_team_id`,
                                                  option ? option.value : ""
                                                )
                                              }
                                              value={
                                                teamOptions?.find(
                                                  (elem) =>
                                                    elem.value ==
                                                    values.proposed_team[index]
                                                      .proposed_team_id
                                                ) || null
                                              }
                                            />
                                            <ErrorMessage
                                              name={`proposed_team[${index}].proposed_team_id`}
                                              component="div"
                                              className="text-danger"
                                            />
                                          </div> */}

                                          <Form.Group className=" form-inner label-text-up">
                                            <div>
                                              <label className="mb-2">
                                                Team's Name
                                              </label>
                                              <Select
                                                name={`proposed_team.${index}.proposed_team_id`}
                                                placeholder="Select"
                                                classNamePrefix="custom-select"
                                                options={customOptions}
                                                // isDisabled={(!values.proposed_team[index].proposed_team_id) || (values.proposed_team[index].proposed_team_id?.length === 0)}
                                                components={{
                                                  DropdownIndicator: null,
                                                }}
                                                onChange={(e) =>
                                                  handleSelectChange(
                                                    e,
                                                    setFieldValue,
                                                    index
                                                  )
                                                }
                                                onInputChange={
                                                  handleInputChange
                                                }
                                                value={
                                                  teamOptions?.find(
                                                    (elem) =>
                                                      elem.value ==
                                                      values.proposed_team[
                                                        index
                                                      ].proposed_team_id
                                                  ) || null
                                                }
                                                isClearable
                                                formatOptionLabel={(data) =>
                                                  data.isAddTeam ? (
                                                    <div
                                                      style={{
                                                        color: "#103D56",
                                                        fontWeight: "bold",
                                                      }}
                                                      onClick={() =>
                                                        setIsModalOpen(true)
                                                      }
                                                    >
                                                      + Add new Team
                                                    </div>
                                                  ) : (
                                                    data.label
                                                  )
                                                }
                                                noOptionsMessage={() =>
                                                  isAddingTeam ? (
                                                    <div
                                                      className="modal-add-teams"
                                                      style={{
                                                        color: "#103D56",
                                                        fontWeight: "bold",
                                                      }}
                                                      onClick={() =>
                                                        setIsModalOpen(true)
                                                      }
                                                    >
                                                      + Add new Team
                                                    </div>
                                                  ) : (
                                                    "No options found"
                                                  )
                                                }
                                              // styles={{
                                              //   placeholder: (base) => ({
                                              //     ...base,
                                              //     color: "#D6D6D6",
                                              //   })
                                              // }}
                                              />

                                              {/* Modal for Adding a New Team */}
                                              <Modal
                                                isOpen={isModalOpen}
                                                onRequestClose={() =>
                                                  setIsModalOpen(false)
                                                }
                                                contentLabel="Add New Team"
                                                style={{
                                                  content: {
                                                    top: "50%",
                                                    left: "50%",
                                                    right: "auto",
                                                    bottom: "auto",
                                                    marginRight: "-50%",
                                                    transform:
                                                      "translate(-50%, -50%)",
                                                    width: "100%",
                                                    maxWidth: "600px",
                                                    boxShadow:
                                                      "0px 2px 20px 0px rgba(0, 35, 149, 0.15)",
                                                    background: "#fff",
                                                    borderRadius: "10px",
                                                    border: "none",
                                                    padding: "30px",
                                                  },
                                                }}
                                              >
                                                <div className="heding-add-new">
                                                  <h3>Add New Team</h3>
                                                </div>

                                                <div className="add-new-input">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Team Name"
                                                    value={newTeamName}
                                                    onChange={(e) =>
                                                      setNewTeamName(
                                                        e.target.value
                                                      )
                                                    }
                                                    onKeyPress={(event) => {
                                                      const regex =
                                                        /^[a-zA-Z\s]*$/;
                                                      if (
                                                        !regex.test(event.key)
                                                      ) {
                                                        event.preventDefault();
                                                      }
                                                    }}
                                                    onPaste={(event) => {
                                                      const pastedData = event.clipboardData.getData('Text');
                                                      const regex = /^[a-zA-Z\s]*$/;
                                                      if (!regex.test(pastedData)) {
                                                        event.preventDefault();
                                                      }
                                                    }}
                                                  />
                                                </div>

                                                <div className="add-new-input label-text-up">
                                                  <label className="mb-2">
                                                    Team’s Country
                                                  </label>
                                                  <Select
                                                    options={optionsCountry}
                                                    placeholder="Select"
                                                    onChange={(option) =>
                                                      setFieldValue(
                                                        `proposed_team.${index}.proposed_team_country`,
                                                        option
                                                          ? option.value
                                                          : ""
                                                      )
                                                    }
                                                    value={
                                                      optionsCountry?.find(
                                                        (opt) =>
                                                          opt.value ==
                                                          values.proposed_team[
                                                            index
                                                          ]
                                                            .proposed_team_country
                                                      ) || null
                                                    }
                                                  />
                                                </div>

                                                <div className="add-new-input label-text-up">
                                                  <label className="mb-2">
                                                    Team’s League
                                                  </label>
                                                  <Select
                                                    options={optionsLeague}
                                                    placeholder="Select"
                                                    onChange={(option) =>
                                                      setFieldValue(
                                                        `proposed_team.${index}.proposed_team_league`,
                                                        option
                                                          ? option.value
                                                          : ""
                                                      )
                                                    }
                                                    value={
                                                      optionsLeague?.find(
                                                        (opt) =>
                                                          opt.value ==
                                                          values.proposed_team[
                                                            index
                                                          ].proposed_team_league
                                                      ) || null
                                                    }
                                                  />
                                                </div>

                                                <div className="add-team-btn">
                                                  <button
                                                    className="add-modal-btn-ps"
                                                    onClick={() =>
                                                      handleAddTeam(
                                                        values,
                                                        setFieldValue,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Add
                                                  </button>
                                                  <button
                                                    onClick={() =>
                                                      setIsModalOpen(false)
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </Modal>
                                            </div>

                                            <ErrorMessage
                                              name={`proposed_team.${index}.proposed_team_id`}
                                              component="div"
                                              className="text-danger"
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="form-group  form-inner label-text-up">
                                            <label className="mb-2">
                                              Team's Country
                                            </label>
                                            <Select
                                              options={optionsCountry}
                                              placeholder="Select"
                                              onChange={(option) =>
                                                setFieldValue(
                                                  `proposed_team.${index}.proposed_team_country`,
                                                  option ? option.value : ""
                                                )
                                              }
                                              value={
                                                optionsCountry?.find(
                                                  (opt) =>
                                                    opt.value ==
                                                    values.proposed_team[index]
                                                      .proposed_team_country
                                                ) || null
                                              }

                                            // isDisabled={
                                            //   !values.proposed_team[index]
                                            //     .proposed_team_league ||
                                            //   isDisabled
                                            // }
                                            />
                                            {touched.proposed_team?.[index]
                                              ?.proposed_team_country &&
                                              errors.proposed_team?.[index]
                                                ?.proposed_team_country && (
                                                <div className="invalid-feedback d-block">
                                                  {
                                                    errors.proposed_team[index]
                                                      .proposed_team_country
                                                  }
                                                </div>
                                              )}
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          {/* {console.log(values.proposed_team[index].proposed_team_country, "6yttttttttttttttttt" ,optionsLeague)} */}

                                          <div className="form-group  form-inner label-text-up">
                                            <label className="mb-2">
                                              Team's League
                                            </label>
                                            <Select
                                              options={optionsLeague}
                                              placeholder="Select"
                                              onChange={(option) =>
                                                setFieldValue(
                                                  `proposed_team.${index}.proposed_team_league`,
                                                  option ? option.value : ""
                                                )
                                              }
                                              value={
                                                optionsLeague?.find(
                                                  (opt) =>
                                                    opt.value ==
                                                    values.proposed_team[index]
                                                      .proposed_team_league
                                                ) || null
                                              }
                                            // isDisabled={isDisabled}
                                            // isDisabled={
                                            //   !values.proposed_team[index]
                                            //     .proposed_team_league ||
                                            //   isDisabled
                                            // }
                                            />
                                            {touched.proposed_team?.[index]
                                              ?.proposed_team_league &&
                                              errors.proposed_team?.[index]
                                                ?.proposed_team_league && (
                                                <div className="invalid-feedback d-block">
                                                  {
                                                    errors.proposed_team[index]
                                                      .proposed_team_league
                                                  }
                                                </div>
                                              )}
                                          </div>
                                        </Col>
                                        <Col lg={12} className="mt-2">
                                          {/* Agent Proposed Player Section */}
                                          <div className="mt-3">
                                            <div className="d-flex justify-content-between label-text-up">
                                              <label className="h-20">
                                                Agent Proposed Player to this
                                                team
                                              </label>
                                            </div>
                                            <div className="cmn-form-fields mt-2">
                                              <Row>
                                                <Col lg={6}>
                                                  <div className="licensed-sec ">
                                                    <h3>Me</h3>
                                                    <div className="form-check form-switch">
                                                      <Field
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        // name="proposed_team[0].is_agent_proposed_player_to_team"
                                                        name={`proposed_team[${index}].is_agent_proposed_player_to_team`}
                                                        value={
                                                          values.proposed_team[
                                                            index
                                                          ]
                                                            .is_agent_proposed_player_to_team
                                                        }
                                                        checked={
                                                          values.proposed_team[
                                                            index
                                                          ]
                                                            .is_agent_proposed_player_to_team ===
                                                          0
                                                        }
                                                        onChange={() =>
                                                          setFieldValue(
                                                            `proposed_team[${index}].is_agent_proposed_player_to_team`,
                                                            values
                                                              .proposed_team[
                                                              index
                                                            ]
                                                              .is_agent_proposed_player_to_team ===
                                                              0
                                                              ? 1
                                                              : 0
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <ErrorMessage
                                                    name={`proposed_team.${index}.is_agent_proposed_player_to_team`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </Col>
                                                <Col lg={6}>
                                                  <div className="licensed-sec">
                                                    <h3>The Associate Agent</h3>
                                                    <div className="form-check form-switch">
                                                      <Field
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        name={`proposed_team[${index}].is_agent_proposed_player_to_team`}
                                                        checked={
                                                          values.proposed_team[
                                                            index
                                                          ]
                                                            .is_agent_proposed_player_to_team ===
                                                          1
                                                        }
                                                        value={
                                                          values.proposed_team[
                                                            index
                                                          ]
                                                            .is_agent_proposed_player_to_team
                                                        }
                                                        onChange={() =>
                                                          setFieldValue(
                                                            `proposed_team.${index}.is_agent_proposed_player_to_team`,
                                                            values
                                                              .proposed_team[
                                                              index
                                                            ]
                                                              .is_agent_proposed_player_to_team ===
                                                              1
                                                              ? 0
                                                              : 1
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>

                                          {values.proposed_team[index]
                                            .is_agent_proposed_player_to_team ===
                                            1 && (
                                              <div className=" mt-3">

                                                <Row>
                                                  <Col lg={6}>
                                                    <div className="form-group mb-3 form-inner mt-2">
                                                      {/* <Field
                                                  type="text"
                                                  name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                  placeholder="Enter Associate Agent Name"
                                                  className={`form-control `}
                                                /> */}

                                                      {/* <Select
                                                        className={`form-control`}
                                                        name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                        placeholder="Enter name"
                                                        options={
                                                          associateAgentOptions
                                                        }
                                                        onChange={(option) =>
                                                          setFieldValue(
                                                            `proposed_team.${index}.proposed_associate_agent_id`,
                                                            option
                                                              ? option.value
                                                              : ""
                                                          )
                                                        }
                                                        // value={
                                                        //   values.proposed_team[index]
                                                        //     .proposed_associate_agent_id ||
                                                        //   ""
                                                        // }
                                                        value={associateAgentOptions?.find( (elem) =>  elem.value == values.proposed_team[ index ].proposed_associate_agent_id
                                                        )}
                                                      /> */}

                                                      <div className="d-flex justify-content-between label-text-up">
                                                        <label className="h-20">
                                                          Name of Associate Agent
                                                        </label>
                                                      </div>

                                                      <Select
                                                        name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                        placeholder="Enter Name"
                                                        classNamePrefix="custom-select"
                                                        options={
                                                          customOptionsAgent
                                                        }
                                                        components={{
                                                          DropdownIndicator: null,
                                                        }}
                                                        onChange={(e) =>
                                                          handleSelectChangeAgent(
                                                            e,
                                                            setFieldValue,
                                                            index
                                                          )
                                                        }
                                                        onInputChange={
                                                          handleInputChangeAgent
                                                        }
                                                        value={
                                                          values?.proposed_team[
                                                            index
                                                          ]
                                                            ?.proposed_associate_agent_id ==
                                                            ""
                                                            ? ""
                                                            : agentOptions?.find(
                                                              (elem) =>
                                                                elem.value ==
                                                                values
                                                                  .proposed_team[
                                                                  index
                                                                ]
                                                                  .proposed_associate_agent_id
                                                            )
                                                        }
                                                        isClearable
                                                        formatOptionLabel={(
                                                          data
                                                        ) =>
                                                          data.isAddingAgent ? (
                                                            <div
                                                              style={{
                                                                color: "#103D56",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                              onClick={() =>
                                                                setIsModalOpenAgent(
                                                                  true
                                                                )
                                                              }
                                                            >
                                                              + Add new Agent
                                                            </div>
                                                          ) : (
                                                            data.label
                                                          )
                                                        }
                                                        noOptionsMessage={() =>
                                                          isAddingAgent ? (
                                                            <div
                                                              className="modal-add-teams"
                                                              style={{
                                                                color: "#103D56",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                              onClick={() =>
                                                                setIsModalOpenAgent(
                                                                  true
                                                                )
                                                              }
                                                            >
                                                              + Add new Agent
                                                            </div>
                                                          ) : (
                                                            "No options found"
                                                          )
                                                        }
                                                      />

                                                      <ErrorMessage
                                                        name={`proposed_team.${index}.proposed_associate_agent_id`}
                                                        component="div"
                                                        className="text-danger"
                                                      />

                                                      <Modal
                                                        isOpen={isModalOpenAgent}
                                                        onRequestClose={() =>
                                                          setIsModalOpenAgent(
                                                            false
                                                          )
                                                        }
                                                        contentLabel="Add New Agent"
                                                        style={{
                                                          content: {
                                                            top: "50%",
                                                            left: "50%",
                                                            right: "auto",
                                                            bottom: "auto",
                                                            marginRight: "-50%",
                                                            transform:
                                                              "translate(-50%, -50%)",
                                                            width: "100%",
                                                            maxWidth: "600px",
                                                            boxShadow:
                                                              "0px 2px 20px 0px rgba(0, 35, 149, 0.15)",
                                                            background: "#fff",
                                                            borderRadius: "10px",
                                                            border: "none",
                                                            padding: "30px",
                                                          },
                                                        }}
                                                      >
                                                        <div className="heding-add-new">
                                                          <h3>Add New Agent</h3>
                                                        </div>
                                                        <div className="add-new-input">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Agent Name"
                                                            value={newAgentName}
                                                            onChange={(e) =>
                                                              setNewAgentName(
                                                                e.target.value
                                                              )
                                                            }
                                                            onKeyPress={(
                                                              event
                                                            ) => {
                                                              const regex =
                                                                /^[a-zA-Z\s]*$/;
                                                              if (
                                                                !regex.test(
                                                                  event.key
                                                                )
                                                              ) {
                                                                event.preventDefault();
                                                              }
                                                            }}
                                                            onPaste={(event) => {
                                                              const pastedData = event.clipboardData.getData('Text');
                                                              const regex = /^[a-zA-Z\s]*$/;
                                                              if (!regex.test(pastedData)) {
                                                                event.preventDefault();
                                                              }
                                                            }}
                                                          />
                                                        </div>
                                                        <div className="add-team-btn">
                                                          <button
                                                            className="add-modal-btn-ps"
                                                            onClick={() =>
                                                              handleAddAgent(
                                                                values,
                                                                setFieldValue,
                                                                index
                                                              )
                                                            }
                                                          >
                                                            Add
                                                          </button>
                                                          <button
                                                            onClick={() =>
                                                              setIsModalOpenAgent(
                                                                false
                                                              )
                                                            }
                                                          >
                                                            Cancel
                                                          </button>
                                                        </div>
                                                      </Modal>
                                                    </div>
                                                  </Col>
                                                  {/* <Col lg={6}>
                                                    <div className="form-group mb-3 form-inner label-text-up">
                                                      <label className="h-20">Agent's Notes</label>
                                                      <Field
                                                        type="text"
                                                        name={`proposed_team.${index}.proposed_associate_agent_note`}
                                                        placeholder="Write notes here"
                                                        className={`form-control`}
                                                      />
                                             
                                                    </div>
                                                  </Col> */}
                                                </Row>
                                              </div>
                                            )}
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </FieldArray>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="pro-posals-right mt-4">
                        {/* <div className="d-flex justify-content-between">
                          <h3 className="h-20">
                            Teams that are interested for the players
                          </h3>
                        </div> */}
                        <div className="cmn-form-fields mt-5">
                          <Row>
                            <div className="label-text-up">
                              <label className="mb-2">
                                Teams that are interested for the player
                              </label>
                              <div className="licensed-sec">
                                <h3>Any team interested for players?</h3>
                                <div className="form-check form-switch">
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    name="is_team_interested_to_player"
                                    checked={
                                      values.is_team_interested_to_player === 1
                                    }
                                    onChange={() => {
                                      const newValue =
                                        values.is_team_interested_to_player ===
                                          1
                                          ? 0
                                          : 1;
                                      setFieldValue(
                                        "is_team_interested_to_player",
                                        newValue
                                      );
                                      setIsInterested(newValue === 1);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <ErrorMessage
                              component="div"
                              name="is_team_interested_to_player"
                              className="text-danger"
                            />
                          </Row>

                          {values.is_team_interested_to_player ? (
                            <div className=" mt-3">
                              <FieldArray name="interested_team">
                                {({ push, remove }) => (
                                  <div>
                                    <div className="d-flex justify-content-between">
                                      {/* <h3 className="h-20 mt-2">Teams</h3> */}
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        onClick={() =>
                                          push({
                                            interested_team_id: "",
                                            interested_team_country: "",
                                            interested_team_league: "",
                                            interested_team_notes: "",
                                          })
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433289 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091V14.5455C10.9091 14.7866 10.8133 15.0178 10.6428 15.1883C10.4723 15.3588 10.2411 15.4545 10 15.4545C9.7589 15.4545 9.52767 15.3588 9.35718 15.1883C9.18669 15.0178 9.09091 14.7866 9.09091 14.5455V10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091V5.45454C9.09091 5.21344 9.18669 4.98221 9.35718 4.81172C9.52767 4.64123 9.7589 4.54545 10 4.54545C10.2411 4.54545 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 10.9091 5.21344 10.9091 5.45454V9.09091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                            fill="#103D56"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                    {values.interested_team.map(
                                      (team, index) => (
                                        <>
                                          <Col lg={12}>
                                            {" "}
                                            <div className="d-flex justify-content-end mt-2 ">
                                              {index !== 0 && (
                                                <button
                                                  type="button"
                                                  style={{
                                                    border: "none",
                                                    background: "none",
                                                  }}
                                                  onClick={() => remove(index)}
                                                  className="btn btn-danger"
                                                >
                                                  <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51808 4.3459 0.761209 6.17316C0.0043329 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091H7.05411C7.05411 11.1502 11.0796 14.1381 10.9091 14.3086C8.95963 12.4289 8.69336 13.8086 10 15.4545C9.7589 15.4545 11.6021 13.1035 11.4316 12.933C11.2612 12.7625 10.6934 14.0477 10.6934 13.8066L9.09091 10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091L10.4316 6.80859C10.4316 6.56749 11.2612 6.97908 11.4316 6.80859C11.6021 6.63811 11.6905 6.30859 11.9316 6.30859C12.1727 6.30859 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 7.16322 8.8498 7.16322 9.09091H10.9091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                                      fill="#103D56"
                                                    />
                                                  </svg>
                                                </button>
                                              )}
                                            </div>
                                          </Col>

                                          <div key={index} className=" mt-2">
                                            <Row>
                                              <Col lg={4}>
                                                <div className="form-group mb-3 form-inner label-text-up">
                                                  <label className="mb-2">
                                                    Team's Name
                                                  </label>
                                                  {/* <Field
                                          type="text"
                                          name={`interested_team.${index}.interested_team_id`}
                                          placeholder="Team Name"
                                          className={`form-control`}
                                        /> */}
                                                  {/* 
                                                <Select
                                                  className={`form-control`}
                                                  name={`interested_team.${index}.interested_team`}
                                                  placeholder="Team Name"
                                                  options={teamOptions}
                                                  onChange={(option) =>
                                                    setFieldValue(
                                                      `interested_team.${index}.interested_team_id`,
                                                      option ? option.value : ""
                                                    )
                                                  }
                                                  // value={
                                                  //   values.interested_team[index]
                                                  //     .interested_team_id || ""
                                                  // }
                                                  value={teamOptions?.find(
                                                    (elem) =>
                                                      elem.value ==
                                                      values.interested_team[
                                                        index
                                                      ].interested_team_id
                                                  )}
                                                /> */}

                                                  <Select
                                                    name={`interested_team.${index}.interested_team_id`}
                                                    placeholder="Select"
                                                    classNamePrefix="custom-select"
                                                    options={customOptions}
                                                    components={{
                                                      DropdownIndicator: null,
                                                    }}
                                                    onChange={(e) =>
                                                      handleSelectInterestedChange(
                                                        e,
                                                        setFieldValue,
                                                        index
                                                      )
                                                    }
                                                    onInputChange={
                                                      handleInputChange
                                                    }
                                                    value={
                                                      teamOptions?.find(
                                                        (elem) =>
                                                          elem.value ==
                                                          values
                                                            .interested_team[
                                                            index
                                                          ].interested_team_id
                                                      ) || null
                                                    }
                                                    isClearable
                                                    formatOptionLabel={(data) =>
                                                      data.isAddTeam ? (
                                                        <div
                                                          style={{
                                                            color: "#103D56",
                                                            fontWeight: "bold",
                                                          }}
                                                          onClick={() =>
                                                            setIsModalOpenInterested(
                                                              true
                                                            )
                                                          }
                                                        >
                                                          + Add new Team
                                                        </div>
                                                      ) : (
                                                        data.label
                                                      )
                                                    }
                                                    noOptionsMessage={() =>
                                                      isAddingTeam ? (
                                                        <div
                                                          className="modal-add-teams"
                                                          style={{
                                                            color: "#103D56",
                                                            fontWeight: "bold",
                                                          }}
                                                          onClick={() =>
                                                            setIsModalOpenInterested(
                                                              true
                                                            )
                                                          }
                                                        >
                                                          + Add new Team
                                                        </div>
                                                      ) : (
                                                        "No options found"
                                                      )
                                                    }
                                                  // styles={{
                                                  //   placeholder: (base) => ({
                                                  //     ...base,
                                                  //     color: "#D6D6D6",
                                                  //   })
                                                  // }}
                                                  />

                                                  <Modal
                                                    isOpen={
                                                      isModalOpenInterested
                                                    }
                                                    onRequestClose={() =>
                                                      setIsModalOpenInterested(
                                                        false
                                                      )
                                                    }
                                                    contentLabel="Add New Team"
                                                    style={{
                                                      content: {
                                                        top: "50%",
                                                        left: "50%",
                                                        right: "auto",
                                                        bottom: "auto",
                                                        marginRight: "-50%",
                                                        transform:
                                                          "translate(-50%, -50%)",
                                                        width: "100%",
                                                        maxWidth: "600px",
                                                        boxShadow:
                                                          "0px 2px 20px 0px rgba(0, 35, 149, 0.15)",
                                                        background: "#fff",
                                                        borderRadius: "10px",
                                                        border: "none",
                                                        padding: "30px",
                                                      },
                                                    }}
                                                  >
                                                    <div className="heding-add-new">
                                                      <h3>Add New Team</h3>
                                                    </div>
                                                    <div className="add-new-input">
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Team Name"
                                                        value={
                                                          newTeamNameInterested
                                                        }
                                                        onChange={(e) =>
                                                          setNewTeamNameInterested(
                                                            e.target.value
                                                          )
                                                        }
                                                        onKeyPress={(event) => {
                                                          const regex =
                                                            /^[a-zA-Z\s]*$/;
                                                          if (
                                                            !regex.test(
                                                              event.key
                                                            )
                                                          ) {
                                                            event.preventDefault();
                                                          }
                                                        }}
                                                        onPaste={(event) => {
                                                          const pastedData = event.clipboardData.getData('Text');
                                                          const regex = /^[a-zA-Z\s]*$/;
                                                          if (!regex.test(pastedData)) {
                                                            event.preventDefault();
                                                          }
                                                        }}
                                                      />
                                                    </div>

                                                    <div className="add-new-input label-text-up">
                                                      <label className="mb-2">
                                                        Team’s Country
                                                      </label>
                                                      <Select
                                                        options={optionsCountry}
                                                        placeholder="Select"
                                                        onChange={(option) =>
                                                          setFieldValue(
                                                            `interested_team.${index}.interested_team_country`,
                                                            option
                                                              ? option.value
                                                              : ""
                                                          )
                                                        }
                                                        value={
                                                          optionsCountry?.find(
                                                            (opt) =>
                                                              opt.value ==
                                                              values
                                                                .interested_team[
                                                                index
                                                              ]
                                                                .interested_team_country
                                                          ) || null
                                                        }
                                                      />
                                                    </div>

                                                    <div className="add-new-input label-text-up">
                                                      <label className="mb-2">
                                                        Team’s League
                                                      </label>
                                                      <Select
                                                        options={optionsLeague}
                                                        placeholder="Select"
                                                        onChange={(option) =>
                                                          setFieldValue(
                                                            `interested_team[${index}].interested_team_league`,
                                                            option
                                                              ? option.value
                                                              : ""
                                                          )
                                                        }
                                                        value={
                                                          optionsLeague?.find(
                                                            (opt) =>
                                                              opt.value ==
                                                              values
                                                                .interested_team[
                                                                index
                                                              ]
                                                                .interested_team_league
                                                          ) || null
                                                        }
                                                      />
                                                    </div>

                                                    <div className="add-team-btn">
                                                      <button
                                                        className="add-modal-btn-ps"
                                                        onClick={() =>
                                                          handleAddTeamIntrested(
                                                            values,
                                                            setFieldValue,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        Add
                                                      </button>
                                                      <button
                                                        onClick={() =>
                                                          setIsModalOpenInterested(
                                                            false
                                                          )
                                                        }
                                                      >
                                                        Cancel
                                                      </button>
                                                    </div>
                                                  </Modal>

                                                  <ErrorMessage
                                                    name={`interested_team.${index}.interested_team_id`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </Col>

                                              <Col lg={4}>
                                                <div className="form-group mb-3 form-inner label-text-up">
                                                  <label className="mb-2">
                                                    Team's Country
                                                  </label>
                                                  <Select
                                                    options={optionsCountry}
                                                    placeholder="Select"
                                                    onChange={(option) =>
                                                      setFieldValue(
                                                        `interested_team.${index}.interested_team_country`,
                                                        option
                                                          ? option.value
                                                          : ""
                                                      )
                                                    }
                                                    // isDisabled={isDisabledInterested}
                                                    // value={
                                                    //   optionsCountry?.find(
                                                    //     (opt) =>
                                                    //       opt.value ===
                                                    //       values.interested_team[
                                                    //         index
                                                    //       ].interested_team_country
                                                    //   ) || null
                                                    // }
                                                    value={
                                                      optionsCountry?.find(
                                                        (opt) =>
                                                          opt.value ==
                                                          values
                                                            .interested_team[
                                                            index
                                                          ]
                                                            .interested_team_country
                                                      ) || null
                                                    }
                                                  // isDisabled={
                                                  //   !values.interested_team[
                                                  //     index
                                                  //   ]
                                                  //     .interested_team_country ||
                                                  //   isDisabledInterested
                                                  // }
                                                  />
                                                  {touched.interested_team?.[
                                                    index
                                                  ]?.interested_team_country &&
                                                    errors.interested_team?.[
                                                      index
                                                    ]
                                                      ?.interested_team_country && (
                                                      <div className="invalid-feedback d-block">
                                                        {
                                                          errors
                                                            .interested_team[
                                                            index
                                                          ]
                                                            .interested_team_country
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>

                                              <Col lg={4}>
                                                <div className="form-group mb-3 form-inner label-text-up">
                                                  <label className="mb-2">
                                                    Team's League
                                                  </label>
                                                  <Select
                                                    options={optionsLeague}
                                                    placeholder="Select"
                                                    // isDisabled={isDisabledInterested}
                                                    onChange={(option) =>
                                                      setFieldValue(
                                                        `interested_team[${index}].interested_team_league`,
                                                        option
                                                          ? option.value
                                                          : ""
                                                      )
                                                    }
                                                    // value={
                                                    //   optionsLeague?.find(
                                                    //     (opt) =>
                                                    //       opt.value ===
                                                    //       values.interested_team[
                                                    //         index
                                                    //       ].interested_team_league
                                                    //   ) || null
                                                    // }

                                                    value={
                                                      optionsLeague?.find(
                                                        (opt) =>
                                                          opt.value ==
                                                          values
                                                            .interested_team[
                                                            index
                                                          ]
                                                            .interested_team_league
                                                      ) || null
                                                    }
                                                  // isDisabled={
                                                  //   !values.interested_team[
                                                  //     index
                                                  //   ]
                                                  //     .interested_team_league ||
                                                  //   isDisabledInterested
                                                  // }
                                                  />
                                                  {/* {touched.interested_team?.[index]
                                          ?.interested_team_league &&
                                          errors.interested_team?.[index]
                                            ?.interested_team_league && (
                                            <div className="invalid-feedback d-block">
                                              {
                                                errors.interested_team[index]
                                                  .interested_team_league
                                              }
                                            </div>
                                          )} */}
                                                </div>
                                              </Col>

                                              {/* <Col lg={6}>
                                                <div className="form-group mb-3 form-inner">
                                                  <Field
                                                    type="text"
                                                    name={`interested_team.${index}.interested_team_notes`}
                                                    placeholder="Notes"
                                                    className={`form-control ${
                                                      touched.interested_team?.[
                                                        index
                                                      ]
                                                        ?.interested_team_notes &&
                                                      errors.interested_team?.[
                                                        index
                                                      ]?.interested_team_notes
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                  />
                                                  <ErrorMessage
                                                    name={`interested_team[${index}].interested_team_notes`}
                                                    component="div"
                                                    className="invalid-feedback"
                                                  />
                                                </div>
                                              </Col> */}
                                            </Row>
                                          </div>
                                        </>
                                      )
                                    )}
                                  </div>
                                )}
                              </FieldArray>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {/* =============================================================================================== */}

                    <div className="form-group mb-3 form-inner other-notes-box label-text-up">
                    <label className="mb-2">Other Notes</label>

                      <Field
                        as="textarea"
                        name="proposed_associate_agent_note"
                        className="form-control"
                        placeholder="Other notes write here"
                        rows={3}
                      />
                      {/* <ErrorMessage
                        name="current_contract_other_notes"
                        component="div"
                        className="text-danger"
                      /> */}
                    </div>

                    <div className="d-flex justify-content-between mb-5">
                      <div className="d-flex">
                        <div className="mt-4 cmn-light-btn ">
                          <button
                            className=""
                            type="button"
                            onClick={handleBack}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="mt-4 me-2 cmn-light-btn save-and-close-btn">
                          <button
                            type="button"
                            onClick={() =>
                              handleSaveAndClose(values, validateForm)
                            }
                            className="next-btn-ps"
                          >
                            Save & Close
                          </button>
                        </div>
                        <div className="mt-4 cmn-light-btn">
                          <button type="submit" className="next-btn-ps">
                            {" "}
                            Save & Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
