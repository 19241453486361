import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// create Player
export const createPlayerAction = createAsyncThunk("createPlayerAction/user/createPlayer", async (data) => {
    const response = await api.post("/user/createPlayer", data);
    return response.data;
  }
);

// edit Player
export const editPlayerAction = createAsyncThunk("editPlayerAction/user/editPlayer", async (data) => {
    const response = await api.put("/user/editPlayer", data);
    return response.data;
  }
);

// get player detail by id

export const getPlayerDataById = createAsyncThunk('getPlayerDataById/shop/id/product',async ({ id, type }, thunkAPI) => {
  const response = await api.get(`/user/getPlayerById`, {
    params: {
      playerId: id,
      type : type
    },
  });
  return response.data;
}
);

// get  Players list
export const getPlayerListAction = createAsyncThunk("getPlayerListAction/user/user/getMyPlayer", async (data) => {
  const response = await api.get("user/getMyPlayer", data);
  return response.data;
}
);

// comapre players
export const getCompareDataAction = createAsyncThunk("getCompareDataAction/user/camparePlayer", async ({id1, id2}) => {
  const response = await api.get("user/camparePlayer",  {
    params : {
      playerId1 : id1,
      playerId2 : id2
    }
  });
  return response.data;
}
);

// external player
export const getGlobalCompareDataAction = createAsyncThunk("getGlobalCompareDataAction/api/user/camparePlayer", async ({id1, id2}) => {
  const response = await api.get("user/camparePlayer",  {
    params : {
      playerId1 : id1,
      playerId2 : id2
    }
  });
  return response.data;
}
);

// get extrnal players
export const getExternalPlayers = createAsyncThunk('getExternalPlayers/user/getintExtPlayers',async () => {
  const response = await api.get(`/user/getintExtPlayers?type=2`);
  return response.data;
}
);


// add team agenda

export const addNewTeam = createAsyncThunk("addNewTeam/user/addAgenda", async (data) => {
  const response = await api.post("/user/addAgenda", data);
  return response.data;
}
);